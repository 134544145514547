import type {
  DetailLocationDto,
  GamMapBounds,
  MapMinMaxRadius,
  SearchLocationDto,
  UserGeoData,
} from '@/core/data/location/location.interface';
import { gambits } from '@/core/gambits';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import { useGeolocation } from '@vueuse/core';
import { clamp } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { GoogleMap } from 'vue3-google-map';
import LatLngBounds = google.maps.LatLngBounds;

export const useMapStore = defineStore(GamStoreId.MAP, () => {
  const { error } = useGeolocation();
  const gMap = ref<InstanceType<typeof GoogleMap>>();
  const defaultMinMax: MapMinMaxRadius = gambits.configService.getMapMinMaxRadius();
  const userLocation = ref<UserGeoData>();
  const currentRadius = ref<number>(defaultMinMax.min);
  const selectedLocationId = ref<string | null>(null);
  const detail = ref<DetailLocationDto | null>(null);
  const mapBounds = ref<GamMapBounds>({
    north: -Infinity,
    south: Infinity,
    east: -Infinity,
    west: Infinity,
  });
  const addMode = ref<boolean>(false);
  const seekingLocation = ref<boolean>(false);
  const satelliteMode = ref<boolean>(false);
  const forceCenter = ref<boolean>(false);
  const newLocation = ref<SearchLocationDto>();

  const setUserLocation = (location?: UserGeoData) => {
    userLocation.value = location;
  };

  const setNewRadius = (mapRadius: number): void => {
    const radiusWithBuffer = Math.round(mapRadius * 3);
    currentRadius.value = clamp(radiusWithBuffer, defaultMinMax.min, defaultMinMax.max);
  };

  const calculateBoundingBox = (lat: number, lon: number, radius: number): GamMapBounds => {
    const earthRadiusInMeters = 6371e3;
    const earthRadiusInDegrees = radius / earthRadiusInMeters;

    const north = lat + earthRadiusInDegrees * (180 / Math.PI);
    const south = lat - earthRadiusInDegrees * (180 / Math.PI);
    const west = lon - (earthRadiusInDegrees * (180 / Math.PI)) / Math.cos(lat * (Math.PI / 180));
    const east = lon + (earthRadiusInDegrees * (180 / Math.PI)) / Math.cos(lat * (Math.PI / 180));

    return { north, south, west, east };
  };

  const updateMapBounds = (latLngBounds?: LatLngBounds): void => {
    if (!latLngBounds) return;
    const center = latLngBounds.getCenter();
    const bounds = calculateBoundingBox(center.lat(), center.lng(), currentRadius.value);

    if (mapBounds.value.south > bounds.south) {
      mapBounds.value.south = bounds.south;
    }
    if (mapBounds.value.north < bounds.north) {
      mapBounds.value.north = bounds.north;
    }
    if (mapBounds.value.west > bounds.west) {
      mapBounds.value.west = bounds.west;
    }
    if (mapBounds.value.east < bounds.east) {
      mapBounds.value.east = bounds.east;
    }
  };

  const isOutsideBoundingBox = (latLngBounds?: LatLngBounds): boolean => {
    if (!latLngBounds) return false;
    const tempBounds = latLngBounds.toJSON();
    const { north, south, west, east } = mapBounds.value;

    return (
      (!!south && tempBounds.south < south) ||
      (!!north && tempBounds.north > north) ||
      (!!west && tempBounds.west < west) ||
      (!!east && tempBounds.east > east)
    );
  };

  const setSelectedLocation = (id: string | null): void => {
    if (selectedLocationId.value) {
      document.getElementById(selectedLocationId.value)?.classList.remove('selected');
    }

    selectedLocationId.value = id;
    if (id) {
      document.getElementById(id)?.classList.add('selected');
    }
  };

  const setError = (geoError: GeolocationPositionError | null): void => {
    error.value = geoError;
  };

  const isGeoDenied = (): boolean => {
    return error.value?.code === 1;
  };

  const setAddMode = (value: boolean): void => {
    addMode.value = value;
  };

  const setSeekingLocation = (value: boolean): void => {
    seekingLocation.value = value;
  };

  const setNewLocation = (value?: SearchLocationDto): void => {
    newLocation.value = value;
  };

  const clearAddMode = (): void => {
    setSeekingLocation(false);
    setAddMode(false);
    setNewLocation();
  };

  const toggleSatelliteMode = () => {
    satelliteMode.value = !satelliteMode.value;
  };

  return {
    gMap,
    currentRadius,
    userLocation,
    selectedLocationId,
    error,
    addMode,
    seekingLocation,
    newLocation,
    satelliteMode,
    forceCenter,
    detail,
    setUserLocation,
    setNewRadius,
    setSelectedLocation,
    updateMapBounds,
    isOutsideBoundingBox,
    isGeoDenied,
    setError,
    setAddMode,
    setSeekingLocation,
    setNewLocation,
    clearAddMode,
    toggleSatelliteMode,
  };
});
