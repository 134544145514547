import { BaseReportReason } from '@/core/data/report/report.type';
import type { PlayerType } from '@/core/data/user/user.type';
import { gambits, localize } from '@/core/gambits';
import { useFormStore } from '@/stores/form.store';
import type { FormId } from '@/views/composables/constants/form.constants';
import type { DateFormObject } from '@/views/composables/models/form.interface';
import type { Validation } from '@vuelidate/core';
import { v4 as uuidv4 } from 'uuid';
import { type Ref } from 'vue';

export default class FormHelper {
  static emojiValidator(text: string): boolean {
    if (!text) return true;
    const emojiRegex = /\p{Extended_Pictographic}/gu;
    const match = text.match(emojiRegex);
    return match?.length === 1;
  }

  static usernameValidator(username: string): boolean {
    const regex = /^[a-z0-9][a-z0-9._]*[a-z0-9]$/i;
    return regex.test(username || '');
  }

  static reportValidator(message: string | null, reason?: string): boolean {
    if (reason !== BaseReportReason.OTHER) return true;
    return !!message?.length;
  }

  static readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result?.toString().split(',')[1] || '');
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  static parseImageFile(image?: string | null): File | string | undefined {
    if (image?.includes('base64')) {
      const base64Image = image.split(',');
      const mime = base64Image[0].match(/:(.*?);/)?.[1];
      const bytes = atob(base64Image[1]);
      let n = bytes.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bytes.charCodeAt(n);
      }

      if (u8arr && mime) {
        const fileExtension = mime.split('/');
        return new File([u8arr], `${uuidv4()}.${fileExtension[1]}`, { type: mime });
      }
    } else if (image === null) {
      return '';
    }
    return undefined;
  }

  static parseDateFromForm(date: DateFormObject): Date {
    const utcDate = Date.UTC(date.year, date.month - 1, date.day);
    return new Date(utcDate);
  }

  static isValidDate(dateObject: DateFormObject): boolean {
    // Check if the date is valid
    const date: Date = this.parseDateFromForm(dateObject);
    if (
      date.getFullYear() !== dateObject.year ||
      date.getMonth() !== dateObject.month - 1 ||
      date.getDate() !== dateObject.day
    ) {
      return false;
    }

    // If minAge is provided, check if the age is above the minimum
    if (dateObject.minAge) {
      const today = new Date();
      let age = today.getFullYear() - dateObject.year;
      const monthDifference = today.getMonth() - (dateObject.month - 1);
      const dayDifference = today.getDate() - dateObject.day;

      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
      }

      if (age < dateObject.minAge) {
        return false;
      }
    }
    return true;
  }

  static getDate(setDate?: string, minAge?: number): DateFormObject {
    if (!setDate)
      return {
        day: -1,
        month: -1,
        year: -1,
      };
    const date = new Date(setDate);

    return {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      day: date.getDate(),
      minAge,
    };
  }

  private static checkUsernameValidation = (v$: Ref<Validation>): boolean => {
    return !v$.value.$silentErrors.filter((error) => error.$property === 'username').length;
  };

  static checkUsername = async (formId: FormId, username: string, v$: Ref<Validation>) => {
    const formStore = useFormStore(formId)();

    if (this.checkUsernameValidation(v$)) {
      formStore.setUsernameLoading(true);
      const response = await gambits.userService.validateUsername(username);
      formStore.setUsernameLoading(false);

      if (response.data && !response.data.isUsernameValid) {
        formStore.setUsernameValidated(true);
        formStore.setUsernameError(null);
      } else {
        formStore.setUsernameValidated(false);
        formStore.setUsernameError({
          label: localize('auth.page.registration.form.username.error.taken'),
        });
      }
    } else if (username.length) {
      formStore.setUsernameValidated(false);
      formStore.setUsernameError({
        label: localize('auth.page.registration.form.username.error.wrong'),
      });
    } else {
      formStore.setUsernameValidated(null);
      formStore.setUsernameError(null);
    }
  };

  static syncPlayerType = (type: PlayerType, playerTypes: PlayerType[], remove?: boolean): PlayerType[] => {
    if (remove) {
      const index = playerTypes.findIndex((item) => item === type);
      if (index > -1) {
        return playerTypes.splice(index, 1);
      }
    } else if (!playerTypes.includes(type)) {
      playerTypes.push(type);
    }
    return playerTypes;
  };
}
