import type { AuthValidateOtpPayload, JWT, UserFormData } from '@/core/data/auth/auth.interface';
import type { CodeResponse } from '@/core/data/auth/google/oauth2';
import { AuthEndpoint, UserEndpoint } from '@/core/network/api/constants/api.url.constant';
import type { HttpClient } from '@/core/network/http/httpClient';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import type { AxiosResponse } from 'axios';

export class AuthApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async authWithEmail(email: string): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.post(AuthEndpoint.OTP_EMAIL, {
      data: { email },
    });
  }

  async authValidateOtp(payload: AuthValidateOtpPayload): Promise<AxiosResponse<GamResponse<JWT>>> {
    return await this.httpClient.post(AuthEndpoint.OTP_VALIDATE, {
      data: payload,
    });
  }

  async authWithGoogle(codeResponse: CodeResponse): Promise<AxiosResponse<GamResponse<JWT>>> {
    return await this.httpClient.post(AuthEndpoint.GOOGLE, {
      data: {
        code: codeResponse.code,
      },
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XmlHttpRequest',
        },
      },
    });
  }

  async authGetToken(code: string): Promise<AxiosResponse<GamResponse<JWT>>> {
    return await this.httpClient.post(AuthEndpoint.TOKEN, {
      data: {
        code,
      },
    });
  }

  async registerUser(formData: UserFormData): Promise<AxiosResponse<GamResponse<JWT>>> {
    return await this.httpClient.post(AuthEndpoint.OTP_REGISTER, {
      data: formData,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async deleteUser(): Promise<AxiosResponse<GamResponse<boolean>>> {
    return await this.httpClient.delete(UserEndpoint.USERS);
  }
}
