import type { UILanguageItem } from '@/core/data/config/uiConfig.interface';
import type { LocalizationRepository } from '@/core/data/localization/localization.repository';
import { ObjectType } from '@/core/data/localization/localization.type';
import type { ConfigService } from '@/core/domain/config.service';
import { createLogger } from '@/core/helpers/logger.helper';

const logger = createLogger('LocalizationService');

export class LocalizationService {
  private localizationRepository: LocalizationRepository;
  private configService: ConfigService;
  private currentLanguage: string | null;

  constructor(localizationRepository: LocalizationRepository, configService: ConfigService) {
    this.localizationRepository = localizationRepository;
    this.configService = configService;
    this.currentLanguage = null;
  }

  localize(key: string, defaultValue?: string): string {
    return this.localizationRepository.translationsForKey(key) || defaultValue || key;
  }

  localizeWithValues(key: string, values: string[]): string {
    const translation = this.localize(key);
    const result = key;
    if (translation && values) {
      return translation.replace(/%s/g, () => {
        const value = values.shift();
        return value?.toString() || '';
      });
    }
    return result;
  }

  localizeType(type: ObjectType, key?: string): string {
    return this.localize(`app.types.${type.toLowerCase()}.${key}`);
  }

  async setClientLanguage(language: string | null): Promise<void> {
    if (language) {
      await this.setLanguage(language);
    }
  }

  private async setLanguage(language: string): Promise<void> {
    if (this.currentLanguage !== language) {
      logger.info(`Setting client language to ${language}`);
      this.configService.setUILanguage(language);
      window.location.reload();
    }
  }

  async setTranslations(): Promise<string> {
    const language = this.configService.getLanguage();
    this.currentLanguage = language;
    document.documentElement.setAttribute('lang', language);
    await this.localizationRepository.setTranslations(language);
    return language;
  }

  public getLanguages(): UILanguageItem[] {
    return [
      {
        name: 'sl',
        displayName: 'SL',
        isActive: true,
      },
      {
        name: 'en',
        displayName: 'EN',
        isActive: false,
      },
    ];
  }
}
