export enum StorageType {
  Session = 'session-storage',
  Local = 'local-storage',
}

export enum StorageKeyType {
  UISettings = 'ui-settings',
  JWToken = 'jw-token',
  User = 'user',
  UserInfo = 'user-info',
  GeoData = 'geo-data',
  GeoDataRefresh = 'geo-data-refresh',
  OtpSend = 'otp-send',
  Register = 'register',
  Filters = 'filters',
  Connect = 'connect',
  AuthConnecting = 'auth-connecting',
  Cookies = 'cookies',
}

export interface StorageRepositoryInterface {
  get<T>(key: StorageKeyType | string): T | null;
  set<T>(key: StorageKeyType | string, data: T): boolean;
  remove(key: StorageKeyType | string): boolean;
  clear(): void;
}
