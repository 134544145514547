<template>
  <div class="gam-nav-footer">
    <div v-if="footerItems" class="footer-items">
      <div v-for="(item, i) in footerItems" :key="`f-it-${i}`" class="footer-item" @click="openLink(item)">
        <div v-if="item.icon" class="nav-icon">
          <gam-icon :name="item.icon" />
        </div>
        <div v-else-if="item.displayName" class="nav-label">
          {{ localize(item.displayName) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UIFooterItem } from '@/core/data/config/uiConfig.interface';
import { gambits, localize } from '@/core/gambits';
import NavigationHelper from '@/core/helpers/navigation.helper';
import GamIcon from '@/views/components/GamIcon.vue';
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

const footerItems = ref<UIFooterItem[] | null>(null);
const router = useRouter();

onBeforeMount(() => {
  footerItems.value = gambits.configService.getFooterItems();
});

const openLink = async (link: UIFooterItem) => {
  await NavigationHelper.openLink(router, link);
};
</script>

<style scoped lang="scss">
.gam-nav-footer {
  display: flex;
  flex-direction: column;
  min-width: 68px;
  gap: var(--spacing-xs);
  padding-top: var(--spacing-menu);
  border-top: 1px solid var(--color-dark-500);
  overflow: hidden;

  .footer-items {
    display: flex;
    width: 227px;
    padding: 0 26px;
    justify-content: space-between;
    align-items: flex-start;

    .footer-item {
      width: max-content;
      cursor: pointer;

      .nav-label {
        color: var(--color-white-70);
        font-size: 14px;
        font-weight: 400;
        line-height: 110%;
        transition: color var(--transition);
      }

      &:hover {
        .nav-label {
          color: var(--color-white-100);
        }
      }
    }
  }
}
</style>
