export enum AppViewsId {
  MAIN = 'gam-wrapper',
  PLAYERS = 'players-view',
  GAMBITS = 'gambits-view',
}

export enum FlowDirections {
  NEXT = 'next',
  PREVIOUS = 'previous',
}

export enum KeyEvent {
  KEY_DOWN = 'keydown',
}

export enum Key {
  UP = 'up',
  DOWN = 'down',
  BACKSPACE = 'Backspace',
  ESCAPE = 'esc',
}
