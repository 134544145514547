export enum AppEndpoint {
  BLOB = '/v1/blobs/{blobHash}',
  FILTERS = '/v1/filters',
}

export enum AuthEndpoint {
  OTP_EMAIL = '/v1/auth/otp',
  OTP_VALIDATE = '/v1/auth/otp/callback',
  OTP_REGISTER = '/v1/auth/register',
  GOOGLE = '/v1/auth/google',
  LICHESS = '/v1/auth/lichess?redirectUrl=',
  LICHESS_RATINGS = '/v1/auth/lichess/ratings?redirectUrl=',
  JWT_REFRESH = '/v1/auth/refresh',
  TOKEN = '/v1/auth/token',
}

export enum LocationEndpoint {
  GET_LOCATIONS = '/v1/locations/',
  GET_LOCATIONS_MAP = '/v1/locations/markers',
  GET_LOCATIONS_SEARCH = '/v1/locations/search',
  GET_LOCATION_PLACE = '/v1/locations/places/{id}',
  LOCATION_FAVORITES = '/v1/locations/{id}/favorites',
  GET_LOCATION_DETAILS = '/v1/locations/{id}',
  LOCATION_IMAGES = '/v1/locations/{id}/images',
  LOCATION_IMAGES_LIKE = '/v1/locations/images/{id}/like',
  LOCATION_IMAGE = '/v1/locations/images/{id}',
  LOCATION_COMMENTS = '/v1/locations/{id}/comments',
  LOCATION_COMMENT_DELETE = '/v1/locations/comments/{id}',
}

export enum GambitEndpoint {
  GET_GAMBITS = '/v1/gambits',
  GET_GAMBIT_DETAILS = '/v1/gambits/{id}/details',
}

export enum UserEndpoint {
  USERS = '/v1/users',
  GET_USER_INFO = '/v1/users/info',
  GET_USER_DETAILS = '/v1/users/{id}/details',
  VALIDATE_USERNAME = '/v1/users/username/valid',
  UPDATE_PROFILE = '/v1/users/profile',
}

export enum ReportEndpoint {
  LOCATION = '/v1/locations/{id}/report',
  LOCATION_COMMENT = '/v1/locations/comments/{id}/report',
  BLOB = '/v1/blobs/{id}/report',
  USER = '/v1/users/{id}/report',
  GAMBIT = '/v1/gambits/{id}/report',
}

export enum AdminEndpoint {
  LOCATION = '/v1/locations/{id}',
  LOCATIONS = '/v1/locations',
  STATS = '/v1/admin/stats',
  USERS = '/v1/users',
  REPORT_LOCATIONS = '/v1/admin/locations/reports',
  REPORT_LOCATION_COMMENTS = '/v1/admin/locations/comments/reports',
  REPORT_BLOBS = '/v1/admin/blobs/reports',
}
