import type { BaseUserInfo, ProfileRatingsUpdatePayload } from '@/core/data/user/user.interface';
import { UserEndpoint } from '@/core/network/api/constants/api.url.constant';
import { HttpClient } from '@/core/network/http/httpClient';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import type { AxiosResponse } from 'axios';

export class RatingApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async updateRatings(ratings: ProfileRatingsUpdatePayload): Promise<AxiosResponse<GamResponse<BaseUserInfo>>> {
    return await this.httpClient.put(UserEndpoint.USERS, {
      data: ratings,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }
}
