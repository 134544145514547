import type { FiltersDto } from '@/core/data/filter/filter.interface';
import { AppEndpoint } from '@/core/network/api/constants/api.url.constant';
import type { HttpClient } from '@/core/network/http/httpClient';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import type { AxiosResponse } from 'axios';

export class FiltersApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getFilters(): Promise<AxiosResponse<GamResponse<FiltersDto>>> {
    return await this.httpClient.get(AppEndpoint.FILTERS);
  }
}
