<template>
  <div v-if="showApp" id="gambits-app">
    <div v-if="gambits.configService.isDevMode()" class="git-hash">
      {{ gambits.configService.getDevInfo() }}
    </div>
    <transition name="router" mode="out-in">
      <gam-nav v-if="isNavVisible" />
    </transition>
    <main :id="id" v-loading="{ state: isLoading, id }">
      <transition name="router" mode="out-in">
        <gam-cookie-notice v-if="showCookieNotice" @update="checkCookies" />
      </transition>
      <router-view v-slot="{ Component }">
        <template v-if="Component">
          <transition name="router" mode="out-in">
            <Suspense>
              <component :is="Component" class="component-wrapper height-100" />
              <template #fallback></template>
            </Suspense>
          </transition>
        </template>
      </router-view>
    </main>
  </div>
</template>

<script setup lang="ts">
import type { CookiesConfig } from '@/core/data/config/uiConfig.interface';
import { gambits } from '@/core/gambits';
import { useAuthStore } from '@/stores/auth.store';
import { useMapStore } from '@/stores/map.store';
import { useNavigationStore } from '@/stores/navigation.store';
import GamNav from '@/views/components/gam-nav/GamNav.vue';
import GamCookieNotice from '@/views/components/gam-notification/GamCookieNotice.vue';
import { AppViewsId } from '@/views/composables/constants/gam.constants';
import { useHead, useSeoMeta } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from 'vue';
import { bootstrap } from 'vue-gtag';

const id: string = AppViewsId.MAIN;
const showApp = ref<boolean>(false);
const language = ref<string>('');
const authStore = useAuthStore();
const navStore = useNavigationStore();
const mapStore = useMapStore();
const { isLoading, isNavVisible } = storeToRefs(navStore);
const cookies = ref<CookiesConfig | null>(null);

onBeforeMount(async () => {
  try {
    cookies.value = gambits.configService.getCookies();
    language.value = await gambits.localizationService.setTranslations();
    mapStore.setUserLocation(gambits.geolocationService.getUserLocation());
    await gambits.filterService.setAppFilters();
  } finally {
    await initApp();
  }
});

onMounted(() => {
  authStore.authorizeUser(gambits.authService.getUser(), gambits.authService.isAuthenticated());
});

const initApp = async (): Promise<void> => {
  return new Promise((resolve) => {
    useHead(gambits.getPageMeta());
    useSeoMeta(gambits.useSeoMeta());
    initAnalytics();
    showApp.value = true;
    resolve();
  });
};

const initAnalytics = () => {
  if (cookies.value?.performance) {
    setTimeout(async () => {
      await bootstrap();
    });
  }
};

const checkCookies = (setCookies: CookiesConfig) => {
  cookies.value = setCookies;
  initAnalytics();
};

const showCookieNotice = computed(() => {
  return cookies.value === null;
});

onUnmounted(() => {
  gambits.geolocationService.clearPositionWatcher();
  mapStore.setUserLocation();
});
</script>

<style scoped lang="scss">
#gambits-app {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;

  #gam-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    transform: scale(1);

    .component-wrapper {
      padding: 0;
      flex-grow: 1;
      flex-shrink: 0 !important;
    }
  }

  .git-hash {
    position: absolute;
    top: 10px;
    right: 20px;
    color: var(--color-white-10);
    z-index: 99999999;
    pointer-events: none;
  }
}

.flex-grow-1 {
  flex-grow: 1 !important;
}
</style>
