export enum GamFilterVariant {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  SWITCH = 'switch',
  RANGE = 'range',
  DROPDOWN = 'dropdown',
}

export enum GamFilterSortPlayer {
  RATING = 'rating',
  DISTANCE = 'distance',
}

export enum GamFilterSortGambits {
  LAST_CREATED = 'last_created',
  GAMBIT_TIME = 'gambit_time',
  DISTANCE = 'distance',
}

export enum GamFilterSortLocation {
  DISTANCE = 'distance',
}

export enum DefaultFilterValue {
  ANY = '*',
}
