import { createLogger } from '@/core/helpers/logger.helper';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const logger = createLogger('CryptoRepository');

export class CryptoRepository {
  private readonly spices: string;
  constructor(spices: string) {
    this.spices = spices;
  }

  encryptData<T>(data: T): string {
    return AES.encrypt(JSON.stringify(data), this.spices).toString();
  }

  decryptData<T>(chipper: string | string[]): T | T[] | null {
    try {
      if (Array.isArray(chipper)) {
        const objArray: T[] = [];
        chipper.forEach((element) => {
          const coded = AES.decrypt(element.toString(), this.spices);
          objArray.push(JSON.parse(coded.toString(Utf8)) as T);
        });
        return (objArray as T[]) && objArray.length > 0 ? objArray : null;
      } else {
        const coded = AES.decrypt(chipper.toString(), this.spices);
        const obj = JSON.parse(coded.toString(Utf8)) as T;
        return (obj as T) && obj ? obj : null;
      }
    } catch (error) {
      logger.error(error);
      return null;
    }
  }
}
