import type { AuthUserDto } from '@/core/data/auth/auth.interface';
import type { LoginMethodType } from '@/core/data/auth/auth.type';
import { RouterAuthType } from '@/core/data/config/uiConfig.interface';
import type { BaseUserInfo } from '@/core/data/user/user.interface';
import { gambits } from '@/core/gambits';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import { defineStore } from 'pinia';
import { nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';

export const useAuthStore = defineStore(GamStoreId.AUTH, () => {
  const isAuthenticated = ref<boolean>(false);
  const isUpdating = ref<boolean>(false);
  const user = ref<AuthUserDto | null>(null);
  const userDetails = ref<BaseUserInfo | null>(null);
  const authToken = ref<string | null>(null);
  const router = useRouter();
  const connecting = ref<LoginMethodType | null>();

  const setAuthenticated = (value: boolean) => {
    isAuthenticated.value = value;
  };

  const isRegistered = (): boolean => {
    return !!user.value?.isActive;
  };

  const isAdmin = (): boolean => {
    return !!user.value?.isAdmin;
  };

  const setUser = (value: AuthUserDto | null) => {
    user.value = value;
  };

  const authorizeUser = (value: AuthUserDto | null, authenticated: boolean) => {
    setUser(value);
    setAuthenticated(authenticated);
  };

  const logoutUser = async () => {
    setUser(null);
    setAuthenticated(false);
    userDetails.value = null;
    await nextTick(async () => {
      await router.push({ name: RouterAuthType.AUTH_SELECT });
    });
  };

  const setUserInfo = async (refresh?: boolean): Promise<void> => {
    isUpdating.value = !!refresh;
    const result = await gambits.userService.getUserInfo(refresh);

    if (result?.data) {
      userDetails.value = result.data;
    }
    isUpdating.value = false;
  };

  const setConnecting = (type: LoginMethodType | null) => {
    connecting.value = type;
  };

  return {
    isAuthenticated,
    isUpdating,
    authToken,
    user,
    userDetails,
    connecting,
    setUser,
    setUserInfo,
    authorizeUser,
    logoutUser,
    isRegistered,
    isAdmin,
    setConnecting,
  };
});
