<template>
  <div :class="['gam-nav', getNavClasses]" @mouseenter="setCollapsed(false)" @mouseleave="setCollapsed(true)">
    <div :class="'gam-nav-container'">
      <div v-if="!isMobile" class="gam-logo">
        <img :src="getAppLogo" :alt="localize('app.name.full')" />
      </div>
      <div v-if="navigationStore.getNavItems()?.length" class="gam-nav-items">
        <gam-nav-item
          v-for="(navItem, index) in navigationStore.getNavItems()"
          :key="`nav-i${index}`"
          v-bind="getNavItem(navItem)"
        />
      </div>
      <div v-if="navigationStore.getNavItemsBottom()?.length" class="gam-nav-items-bottom">
        <gam-nav-item
          v-for="(navItem, bIndex) in navigationStore.getNavItemsBottom()"
          :key="`nav-ib${bIndex}`"
          v-bind="getNavItem(navItem)"
        />
      </div>
      <gam-nav-footer v-if="showNavFooter" />
    </div>
    <transition name="fade">
      <div v-if="isMoreOpened" v-on-click-outside="closeMoreMenu" :class="['gam-nav-more-container']">
        <div v-if="navigationStore.getNavItemsMore()?.length" class="gam-nav-items-more">
          <gam-nav-item
            v-for="(navItem, mIndex) in navigationStore.getNavItemsMore()"
            :key="`nav-im${mIndex}`"
            :is-more="true"
            v-bind="getNavItem(navItem)"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { gambits, localize } from '@/core/gambits';
import { useAppStore } from '@/stores/app.store';
import { useAuthStore } from '@/stores/auth.store';
import { useNavigationStore } from '@/stores/navigation.store';
import GamNavFooter from '@/views/components/gam-nav/GamNavFooter.vue';
import GamNavItem from '@/views/components/gam-nav/GamNavItem.vue';
import type { GamNavItemType } from '@/views/composables/models/components/GamNav';
import { vOnClickOutside } from '@vueuse/components';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount } from 'vue';

const navigationStore = useNavigationStore();
const { isMobile, isCollapsed, isMoreOpened } = storeToRefs(navigationStore);
const authStore = useAuthStore();
const { isAuthenticated } = storeToRefs(authStore);

const appStore = useAppStore();
const { drawerOpened } = storeToRefs(appStore);

onBeforeMount(() => {
  navigationStore.setNavItems(gambits.configService.getMainMenu());
});

const setCollapsed = debounce((value: boolean) => {
  navigationStore.setCollapsed(value);
}, 200);

const closeMoreMenu = (event: MouseEvent) => {
  event.stopPropagation();
  navigationStore.setMoreOpened(false);
};

const getProfileName = (nav: GamNavItemType): string => {
  if (nav.name === RouterNameType.PROFILE && !isAuthenticated.value) {
    return 'app.nav.login';
  } else {
    return nav.displayName;
  }
};

const getNavItem = (navItem: GamNavItemType): GamNavItemType => {
  return {
    ...navItem,
    displayName: getProfileName(navItem),
  };
};

const getNavClasses = computed(() => ({
  'is-mobile': isMobile.value,
  'is-collapsed': isCollapsed.value,
  'side-panel-opened': drawerOpened.value,
  'is-footer': showNavFooter.value,
}));

const getAppLogo = computed((): string => {
  return isCollapsed.value ? '/images/logo-gambits-c.svg' : '/images/logo-gambits.svg';
});

const showNavFooter = computed((): boolean => {
  return !isMobile.value && !authStore.isRegistered();
});
</script>

<style scoped lang="scss">
$message-pop-margin: 6px;

.gam-nav {
  display: flex;
  padding: var(--spacing-xxl) 20px var(--spacing-menu) 20px;
  background-color: var(--color-dark-600);
  width: 268px;
  min-width: 268px;
  min-height: 100%;
  border-right: 1px solid var(--color-dark-500);
  transition: all var(--transition);
  z-index: 700;
  flex-shrink: 0;

  .gam-nav-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .gam-logo {
      width: 100%;
      padding: 0 var(--spacing-md);
      overflow: hidden;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-bottom: var(--spacing-menu);

      img {
        width: 144px;
        min-height: 36px;
        max-height: 36px;
        transition: width var(--transition);
      }
    }

    .gam-nav-items {
      display: flex;
      flex-direction: inherit;
      min-width: 68px;
      gap: var(--spacing-xs);
      flex: 1 1 auto;
    }

    .gam-nav-items-bottom {
      display: flex;
      flex-direction: column;
      min-width: 68px;
      gap: var(--spacing-xs);
      padding-top: var(--spacing-menu);
      border-top: 1px solid var(--color-dark-500);
    }
  }

  &.is-collapsed {
    width: 108px;
    min-width: 108px;
  }

  &.is-mobile {
    width: 100%;
    background: var(--color-gradient-dark);
    transition:
      opacity var(--transition-router),
      bottom var(--transition);
    background-color: transparent;
    border: 0;
    position: fixed;
    min-height: 78px;
    padding: 20px;
    bottom: 0;

    &.side-panel-opened {
      pointer-events: none;
      opacity: 0;
      bottom: -78px !important;
    }

    .gam-nav-container {
      flex-direction: row;

      .gam-nav-items {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        min-height: 0;
        flex: auto;
        gap: 0;
      }
    }

    .gam-nav-more-container {
      position: absolute;
      width: 220px;
      background-color: var(--color-dark-700);
      border: 1px solid var(--color-white-5);
      backdrop-filter: blur(15px);
      border-radius: var(--radius-large);
      padding: var(--spacing-l);
      bottom: 86px;
      right: 10px;

      .gam-nav-items-more {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-md);

        .gam-nav-item {
          width: 100%;
        }
      }
    }
  }

  &.is-footer {
    .gam-nav-container {
      .gam-nav-items-bottom {
        padding: var(--spacing-menu) 0;
      }
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity var(--transition);
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}
</style>
