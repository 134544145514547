import type { JWT } from '@/core/data/auth/auth.interface';
import type { JwtRepository } from '@/core/data/auth/jwt.repository';
import { createLogger } from '@/core/helpers/logger.helper';
import type { GamRequestConfig } from '@/core/network/http/httpClient.interface';
import { limitToOnlyOneRunningPromise } from '@/core/network/http/promises';
import fetch from 'cross-fetch';

const logger = createLogger('JWT Service');

export class JwtService {
  private readonly repository: JwtRepository;
  private readonly serviceUrl: string;
  private readonly refreshTokenEndpoint = '/v1/auth/refresh';
  private refreshTokenPromise: (() => Promise<void>) | null = null;

  constructor(repository: JwtRepository, serviceUrl: string) {
    this.repository = repository;
    this.serviceUrl = serviceUrl;
  }

  async refreshToken(): Promise<void> {
    if (!this.refreshTokenPromise) {
      this.refreshTokenPromise = limitToOnlyOneRunningPromise(async (): Promise<void> => {
        const tokenInfo = this.getTokenInfo();
        const url = `${this.serviceUrl}${this.refreshTokenEndpoint}`;
        try {
          if (tokenInfo) {
            const response: Response = await fetch(url, {
              method: 'POST',
              mode: 'cors',
              cache: 'no-cache',
              credentials: 'same-origin',
              headers: {
                authorization: `Bearer ${tokenInfo.refreshToken}`,
              },
            });

            if (response?.status === 201) {
              this.setTokenInfo(await response.json());
            } else if (response?.status >= 400) {
              logger.error('JWT refresh failed');
              await this.remove();
            }
          }
        } catch (ex) {
          logger.error('JWT refresh failed', ex);
          await this.remove();
        }
        this.refreshTokenPromise = null;
      }).bind(this);
    }
    return this.refreshTokenPromise?.();
  }

  getTokenInfo(): JWT | null {
    return this.repository.getJWToken();
  }

  setTokenInfo(tokenInfo: JWT | null): void {
    if (tokenInfo) {
      this.repository.setJWToken(tokenInfo);
    }
  }

  async remove(): Promise<void> {
    return this.repository.logout();
  }

  getAuthorizationConfig<P>(config?: GamRequestConfig<P>): GamRequestConfig<P> {
    if (config) {
      return {
        ...config,
        headers: {
          ...config.headers,
          ...this.repository.setHeadersFromJWT(),
        },
      };
    } else {
      return {
        headers: this.repository.setHeadersFromJWT(),
      };
    }
  }
}
