export enum GamChipSize {
  NORMAL = 'normal',
  SMALL = 'small',
  X_SMALL = 'x-small',
}

export enum GamChipVariant {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  ERROR = 'error',
}
