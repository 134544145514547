import type { BaseGambitDto, GambitDto } from '@/core/data/gambit/gambit.interface';
import { createLogger } from '@/core/helpers/logger.helper';
import type { GambitApi } from '@/core/network/api/gambit/gambit.api';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import { HandleApiError } from '@/core/network/http/httpError';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';

const logger = createLogger('GambitRepository');

export class GambitRepository {
  private readonly httpAPI: GambitApi;

  constructor(httpAPI: GambitApi) {
    this.httpAPI = httpAPI;
  }

  async getGambits(listId: GamListId): Promise<GamResponse<BaseGambitDto[]> | null> {
    try {
      const response = await this.httpAPI.getGambits(listId);
      return response.data?.data ? { data: response.data.data, cursor: response.data.cursor } : null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async getGambit(id: string): Promise<GamResponse<GambitDto> | null> {
    try {
      const response = await this.httpAPI.getGambit(id);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }
}
