import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import { useBreakpoints } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAppStore = defineStore(GamStoreId.APP, () => {
  const breakpoints = useBreakpoints({
    mobile: 0, // optional
    tablet: 640,
    laptop: 1024,
    desktop: 1280,
  });
  const activeBreakpoint = breakpoints.active();
  const dialogOpened = ref<boolean>(false);
  const drawerOpened = ref<boolean>(false);
  const imageExpanded = ref<boolean>(false);

  const isMobile = (): boolean => {
    return activeBreakpoint.value === 'mobile';
  };

  const setDialogOpened = (value: boolean) => {
    dialogOpened.value = value;
  };

  const setDrawerOpened = (value: boolean) => {
    drawerOpened.value = value;
  };

  const setImageExpanded = (value: boolean) => {
    imageExpanded.value = value;
  };

  return {
    activeBreakpoint,
    dialogOpened,
    drawerOpened,
    imageExpanded,
    setDialogOpened,
    setDrawerOpened,
    setImageExpanded,
    isMobile,
  };
});
