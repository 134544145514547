import { CryptoRepository } from '@/core/data/storage/crypto.repository';
import { createLogger } from '@/core/helpers/logger.helper';
import type { StorageKeyType, StorageRepositoryInterface, StorageType } from './storage.interface';

const logger = createLogger('StorageRepository');

export class StorageRepository implements StorageRepositoryInterface {
  private readonly crypto: CryptoRepository;
  private readonly store: Storage;
  private readonly storeType: StorageType;

  constructor(store: Storage, storeType: StorageType, spices: string) {
    this.store = store;
    this.storeType = storeType;
    this.crypto = new CryptoRepository(spices);
  }

  set<T>(key: StorageKeyType, data: T): boolean {
    try {
      logger.debug(`Setting data with key: ${key} to {${this.storeType}}`);
      this.store.setItem(key, this.crypto.encryptData<T>(data));
      return true;
    } catch (error) {
      logger.error(error);
      return false;
    }
  }

  get<T>(key: StorageKeyType): T | null {
    try {
      const chipper = this.store.getItem(key);
      if (chipper) {
        const object = this.crypto.decryptData<T>(chipper);
        if (object) {
          logger.debug(`Getting data with key: ${key} from {${this.storeType}}`);
          return object as T;
        }
      }
      return null;
    } catch (error) {
      logger.error(error);
      return null;
    }
  }

  remove(key: StorageKeyType): boolean {
    try {
      logger.debug(`Removing data with key: ${key} from {${this.storeType}}`);
      this.store.removeItem(key);
      return true;
    } catch (error) {
      logger.error(error);
      return false;
    }
  }

  clear() {
    try {
      logger.debug(`Clearing storage from {${this.storeType}}`);
      this.store.clear();
      return true;
    } catch (error) {
      logger.error(error);
      return false;
    }
  }
}
