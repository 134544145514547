import type { AuthUserDto, JWT } from '@/core/data/auth/auth.interface';
import type { StorageRepositoryInterface } from '@/core/data/storage/storage.interface';
import { StorageKeyType } from '@/core/data/storage/storage.interface';
import { useAuthStore } from '@/stores/auth.store';
import { AxiosHeaders } from 'axios';

export class JwtRepository {
  private readonly storage: StorageRepositoryInterface;

  constructor(storage: StorageRepositoryInterface) {
    this.storage = storage;
  }

  getJWToken(): JWT | null {
    return this.storage.get<JWT>(StorageKeyType.JWToken);
  }

  getUser(): AuthUserDto | null {
    return this.storage.get<AuthUserDto | null>(StorageKeyType.User);
  }

  setJWToken(tokenInfo: JWT): void {
    const payloadInfo = this.parseJwt(tokenInfo.accessToken);

    // Store JWT data to storage
    this.storage.set<JWT>(StorageKeyType.JWToken, tokenInfo as JWT);
    this.storage.set<AuthUserDto>(StorageKeyType.User, payloadInfo);
  }

  parseJwt(token: string): AuthUserDto {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload) as AuthUserDto;
  }

  setHeadersFromJWT(): AxiosHeaders {
    const headers: AxiosHeaders = new AxiosHeaders();

    // Set Authorization header
    const jwt = this.getJWToken();
    if (jwt) {
      headers.authorization = `Bearer ${jwt.accessToken}`;
    }

    return headers;
  }

  async logout(): Promise<void> {
    this.storage.remove(StorageKeyType.JWToken);
    this.storage.remove(StorageKeyType.User);
    this.storage.remove(StorageKeyType.UserInfo);
    await useAuthStore().logoutUser();
  }
}
