export enum GamButtonSize {
  LARGE = 'large',
  ACTIONS = 'actions',
  SMALL = 'small',
  X_SMALL = 'x-small',
}

export enum GamButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum GamButtonMessageVariant {
  DEFAULT = 'default',
  ERROR = 'error',
}
