import { AppViewsId } from '@/views/composables/constants/gam.constants';
import type { LoadingType } from '@/views/composables/models/gam.interface';
import type { App, DirectiveBinding } from 'vue';

const getVideo = (id: AppViewsId): HTMLVideoElement => {
  const video: HTMLVideoElement = document.createElement('video');
  const source: HTMLSourceElement = document.createElement('source');
  video.id = `LoadCrown-${id}`;
  video.loop = true;
  video.autoplay = true;
  video.muted = true;
  video.style.opacity = '0';
  source.src = '/images/Loading-Calma.webm';
  source.type = 'video/webm';
  video.append(source);
  return video;
};

let video: HTMLVideoElement;

const updateClass = (el: HTMLElement, data: LoadingType) => {
  if (data?.state) {
    el.classList.add('is-loading');
    document.getElementById(data.id)?.appendChild(video);
    setTimeout(() => {
      el.classList.add('visible');
    }, 210);
  } else {
    el.classList.remove('visible');
    setTimeout(() => {
      el.classList.remove('is-loading');
      el.childNodes.forEach((item) => {
        if (item.nodeName === 'VIDEO') {
          document.getElementById(data.id)?.removeChild(video);
        }
      });
    }, 210);
  }
};

export default {
  install: (vue: App) => {
    vue.directive('loading', {
      created: (el: HTMLElement, binding: DirectiveBinding<LoadingType>) => {
        video = getVideo(binding.value.id);
      },
      mounted: (el: HTMLElement, binding: DirectiveBinding<LoadingType>) => {
        updateClass(el, binding.value);
      },
      updated: async (el: HTMLElement, binding: DirectiveBinding<LoadingType>) => {
        updateClass(el, binding.value);
      },
      unmounted: (el: HTMLElement, binding: DirectiveBinding<LoadingType>) => {
        updateClass(el, binding.value);
      },
    });
  },
};
