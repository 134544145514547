import type { CountryDto, MonthDto } from '@/core/domain/static/static.interface';
import { createLogger } from '@/core/helpers/logger.helper';
import type { Result } from '@/core/network/http/httpClient.interface';
import axios from 'axios';

const logger = createLogger('StaticService');

export class StaticService {
  private countries: CountryDto[] = [];
  private months: MonthDto[] = [];
  constructor() {
    axios.get('/static/countries.json').then((response) => {
      this.countries = response.data || [];
    });
    axios.get('/static/months.json').then((response) => {
      this.months = response.data || [];
    });
  }

  async getMonths(): Promise<Result<MonthDto[]>> {
    return {
      data: this.months,
    };
  }

  async getCountries(search?: string): Promise<Result<CountryDto[]>> {
    return {
      data: this.countries.filter((country) => country.name.toLowerCase().startsWith(search?.toLowerCase() || '')),
    };
  }

  async loadHtmlContent(url: string): Promise<string | null> {
    try {
      const response = await fetch(url);
      if (response.ok) {
        return await response.text();
      } else {
        logger.error('Failed to load HTML content');
        return null;
      }
    } catch (error) {
      logger.error('Error fetching HTML content:', error);
      return null;
    }
  }
}
