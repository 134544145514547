export enum GamListId {
  ANY = 'any',
  PLAYERS = 'players',
  PLAYERS_FILTER_LOCATION = 'players-filter-location',
  PLAYERS_FILTER_PLAYER = 'players-filter-player',
  GAMBITS = 'gambits',
  LOCATIONS = 'locations',
  LOCATION_DETAIL = 'location-detail',
  LOCATION_SEARCH = 'location-search',
  LOCATION_IMAGES = 'location-images',
  LOCATION_COMMENTS = 'location-comments',
  ADD_LOCATION = 'add-location',
  MAP_PINS = 'map-pins',
  MONTHS = 'months',
  COUNTRIES = 'countries',
  ADMIN_USERS = 'admin-users',
  ADMIN_LOCATIONS = 'admin-locations',
  ADMIN_REPORTS = 'admin-reports',
}
