import type { AxiosResponse } from 'axios';
import pino from 'pino';
import type { ApiError, HttpResponseError } from './httpClient.interface';

export class HttpError {
  status: number;
  statusText: string;
  error: ApiError;

  constructor(response?: AxiosResponse) {
    if (response) {
      this.status = response.status;
      this.statusText = response.statusText;
      this.error = this.setError(response.data);
    } else {
      this.status = 400;
      this.statusText = 'Something went wrong.';
      this.error = this.setError();
    }
  }

  private setError(data?: HttpResponseError): ApiError {
    return {
      code: data?.code || this.status,
      message: data?.message || this.statusText,
      errorKey: data?.key || 'error_generic',
    };
  }
}

export const generalError = (): ApiError => {
  return {
    code: 400,
    message: 'Something went wrong.',
    errorKey: 'error_generic',
  };
};

export const HandleApiError = (e: unknown, logger: pino.Logger) => {
  const httpError = e as HttpError;
  logger.error(`${httpError.error.code} => ${httpError.error.message}`);
  throw httpError.error;
};
