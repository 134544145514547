import { detectLoggingLevel } from '@/core/helpers/logger.helper';

detectLoggingLevel();

import './ui/css/style.scss';

import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import Vue3TouchEvents, { type Vue3TouchEventsOptions } from 'vue3-touch-events';

import { gambits } from '@/core/gambits';
import loadingDirective from '@/directives/loading.directive';
import tooltipDirective from '@/directives/tooltip.directive';
import * as Sentry from '@sentry/vue';
import { GesturePlugin } from '@vueuse/gesture';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import App from './App.vue';
import router from './router';

const app = createApp(App);
const head = createHead();
const sentryConfig = gambits.configService.getSentryConfig();

if (sentryConfig?.dsn) {
  Sentry.init({
    app,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    ...sentryConfig,
  });
}

app.use(head);
app.use(createPinia());
app.use(router);
app.use(PerfectScrollbarPlugin);
app.use(GesturePlugin);
app.use(loadingDirective);
app.use(tooltipDirective);
app.use<Vue3TouchEventsOptions>(Vue3TouchEvents, {
  disableClick: true,
});

app.use(
  VueGtag,
  {
    appName: 'Gambits',
    bootstrap: false,
    config: {
      id: import.meta.env.VITE_GTM_ID || '',
    },
  },
  router,
);

app.mount('#Gambits');
