export enum LocationType {
  MY_LOCATION = 'current',
  HOME = 'home',
  CHESS_TABLE = 'chess_table',
  GIANT_CHESS = 'giant_chess',
  CAFFE_CHESS = 'cafe_chess',
  CHESS_FRIENDLY = 'chess_friendly',
  CLUB = 'club',
  FAVORITE = 'favorite',
}

export enum MapLocationType {
  LOCATION = 'location',
  GAMBIT = 'gambit',
}

export enum MapLocationSource {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}
