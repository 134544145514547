import type { ReportDto } from '@/core/data/report/report.interface';
import type { ReportType } from '@/core/data/report/report.type';
import { createLogger } from '@/core/helpers/logger.helper';
import type { ReportApi } from '@/core/network/api/report.api';
import { HttpError } from '@/core/network/http/httpError';

const logger = createLogger('LocationRepository');

export class ReportRepository {
  private readonly httpAPI: ReportApi;

  constructor(httpAPI: ReportApi) {
    this.httpAPI = httpAPI;
  }

  async addReport(id: string, type: ReportType, report: ReportDto): Promise<boolean> {
    try {
      const response = await this.httpAPI.reportItem(id, type, report);
      return response.status >= 200 && response.status < 300;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }
}
