import type {
  AddLocationCommentData,
  AddLocationFormData,
  BaseLocationDto,
  CommentDto,
  DetailLocationDto,
  GeoLocation,
  ImageDto,
  MapLocationDto,
  SearchLocationDto,
} from '@/core/data/location/location.interface';
import {
  baseLocationFields,
  detailsLocationCommentsFields,
  detailsLocationFields,
  detailsLocationImagesFields,
} from '@/core/network/api/constants/api.fields.constant';
import { LocationEndpoint } from '@/core/network/api/constants/api.url.constant';
import { HttpClient } from '@/core/network/http/httpClient';
import type {
  GamResponse,
  GetLocationTableFilters,
  GetTableParams,
  SearchLocationParams,
} from '@/core/network/http/httpClient.interface';
import { useFilterStore } from '@/stores/filter.store';
import { useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamFilterSortLocation } from '@/views/composables/constants/main/filter.constants';
import type { AxiosResponse } from 'axios';
import { storeToRefs } from 'pinia';

export class LocationApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  private getLocationsParams(id: GamListId, geo: GeoLocation): GetLocationTableFilters {
    const tableStore = useTableStore(id)();
    const filterStore = useFilterStore<GetLocationTableFilters>(GamListId.LOCATIONS)();
    const { userLocation, currentRadius } = storeToRefs(useMapStore());

    const userLatitude = userLocation.value?.location.lat || geo.lat;
    const userLongitude = userLocation.value?.location.lng || geo.lng;

    return {
      ...tableStore.tableParams,
      ...tableStore.getLocationParam(),
      ...filterStore.getFilters(),
      latitude: tableStore.getLocationParam()?.latitude || filterStore.getFilters()?.latitude || userLatitude,
      longitude: tableStore.getLocationParam()?.longitude || filterStore.getFilters()?.longitude || userLongitude,
      userLatitude,
      userLongitude,
      radius: currentRadius.value, // we store radius in m
    };
  }

  private getSearchLocationParams(id: GamListId, geo: GeoLocation): SearchLocationParams {
    const mapStore = useMapStore();
    const tableStore = useTableStore<BaseLocationDto>(id)();

    return {
      query: tableStore.tableParams.search || '',
      latitude: mapStore.userLocation?.location.lat || geo.lat,
      longitude: mapStore.userLocation?.location.lng || geo.lng,
    };
  }

  async getLocations(
    listId: GamListId,
    geo: GeoLocation,
    includeTopLocation?: boolean,
  ): Promise<AxiosResponse<GamResponse<BaseLocationDto[]>>> {
    return this.httpClient.get<BaseLocationDto[], GetLocationTableFilters>(LocationEndpoint.GET_LOCATIONS, {
      config: {
        params: {
          ...this.getLocationsParams(listId, geo),
          sortBy: GamFilterSortLocation.DISTANCE,
          fields: baseLocationFields.join(','),
          includeTopLocation,
        },
      },
    });
  }

  async getMapLocations(listId: GamListId, geo: GeoLocation): Promise<AxiosResponse<GamResponse<MapLocationDto[]>>> {
    return this.httpClient.get<MapLocationDto[], GetLocationTableFilters>(LocationEndpoint.GET_LOCATIONS_MAP, {
      config: {
        params: this.getLocationsParams(listId, geo),
      },
    });
  }

  async searchLocations(listId: GamListId, geo: GeoLocation): Promise<AxiosResponse<GamResponse<SearchLocationDto[]>>> {
    return this.httpClient.get<SearchLocationDto[], SearchLocationParams>(LocationEndpoint.GET_LOCATIONS_SEARCH, {
      config: {
        params: this.getSearchLocationParams(listId, geo),
      },
    });
  }

  async getLocationPlace(mapBoxId: string): Promise<AxiosResponse<GamResponse<GeoLocation>>> {
    return this.httpClient.get<GeoLocation>(LocationEndpoint.GET_LOCATION_PLACE, {
      urlParams: { id: mapBoxId },
    });
  }

  async getLocation(id: string): Promise<AxiosResponse<GamResponse<DetailLocationDto>>> {
    return this.httpClient.get<DetailLocationDto, GetTableParams>(LocationEndpoint.GET_LOCATION_DETAILS, {
      config: {
        params: {
          fields: detailsLocationFields.join(','),
        },
      },
      urlParams: { id },
    });
  }

  async getLocationImages(id: string): Promise<AxiosResponse<GamResponse<ImageDto[]>>> {
    return this.httpClient.get<ImageDto[], GetTableParams>(LocationEndpoint.LOCATION_IMAGES, {
      urlParams: { id },
      config: {
        params: {
          fields: detailsLocationImagesFields.join(','),
        },
      },
    });
  }

  async getLocationComments(id: string): Promise<AxiosResponse<GamResponse<CommentDto[]>>> {
    return this.httpClient.get<CommentDto[], GetTableParams>(LocationEndpoint.LOCATION_COMMENTS, {
      urlParams: { id },
      config: {
        params: {
          fields: detailsLocationCommentsFields.join(','),
        },
      },
    });
  }

  async addLocation(data: AddLocationFormData): Promise<AxiosResponse<GamResponse<BaseLocationDto>>> {
    return await this.httpClient.post(LocationEndpoint.GET_LOCATIONS, {
      data,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async toggleFavorite(id: string, favorite?: boolean): Promise<AxiosResponse<GamResponse<void>>> {
    if (favorite) {
      return await this.httpClient.post(LocationEndpoint.LOCATION_FAVORITES, {
        urlParams: { id },
      });
    } else {
      return await this.httpClient.delete(LocationEndpoint.LOCATION_FAVORITES, {
        urlParams: { id },
      });
    }
  }

  async toggleImageLike(id: string, like: boolean): Promise<AxiosResponse<GamResponse<void>>> {
    if (like) {
      return await this.httpClient.post(LocationEndpoint.LOCATION_IMAGES_LIKE, {
        urlParams: { id },
      });
    } else {
      return await this.httpClient.delete(LocationEndpoint.LOCATION_IMAGES_LIKE, {
        urlParams: { id },
      });
    }
  }

  async deleteLocationImage(id: string): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.delete(LocationEndpoint.LOCATION_IMAGE, {
      urlParams: { id },
    });
  }

  async addPhoto(id: string, photo?: File | string | null): Promise<AxiosResponse<GamResponse<ImageDto>>> {
    return await this.httpClient.post(LocationEndpoint.LOCATION_IMAGES, {
      urlParams: { id },
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      data: {
        image: photo,
      },
    });
  }

  async addComment(id: string, data: AddLocationCommentData): Promise<AxiosResponse<GamResponse<CommentDto>>> {
    return await this.httpClient.post(LocationEndpoint.LOCATION_COMMENTS, {
      urlParams: { id },
      data,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async deleteComment(id: string): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.delete(LocationEndpoint.LOCATION_COMMENT_DELETE, {
      urlParams: { id },
    });
  }

  // async getUserLocation(apiKey: string): Promise<AxiosResponse<GamResponse<UserGeoData>>> {
  //   return this.httpClient.post<UserGeoData, GetTableParams>(this.getGeoLocationUrl.replace(/%s/g, apiKey));
  // }
}
