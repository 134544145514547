export enum GamIconSize {
  SMALL = '14px',
  NORMAL = '16px',
  LARGE = '18px',
  X_LARGE = '24px',
}

export enum GamIconSpecialSize {
  PIN = '20px',
  MY_LOCATION = '36px',
  TOURNAMENT = '68px',
}

export enum GamIconName {
  '2D_MAP' = '2d-map',
  '3D_MAP' = '3d-map',
  ADMIN = 'admin',
  APPLE = 'apple',
  APPLEPAY = 'applepay',
  ARROW_DOWN = 'arrow-down',
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT = 'arrow-right',
  ARROW_UP = 'arrow-up',
  CAMERA = 'camera',
  CHECK = 'check',
  CHESS_COM = 'chess-com',
  CLUB = 'club',
  COFFEE = 'coffee',
  CORNER = 'corner',
  CROWN = 'crown',
  DOTS_H = 'dots-h',
  DOTS_H_EMPTY = 'dots-h-empty',
  DOTS_V = 'dots-v',
  DOUBLE_CHECK = 'double-check',
  EDIT = 'edit',
  ENVELOPE = 'envelope',
  FEED = 'feed',
  FIDE = 'fide',
  FILTER = 'filter',
  FILTER_APPLIED = 'filter-applied',
  G_CROWN = 'g-crown',
  GAMBIT = 'gambit',
  GAMBIT_EMPTY = 'gambit-empty',
  GIANT_CHESS = 'giant-chess',
  GOOGLE = 'google',
  HEART = 'heart',
  HEART_EMPTY = 'heart-empty',
  HOUSE = 'house',
  INSTAGRAM = 'ig',
  INVITE = 'invite',
  LAST_TIME = 'last-time',
  LICHESS = 'lichess',
  LIKE = 'like',
  LIST = 'list',
  LOADING = 'loading',
  LOCATION = 'location',
  LOCATION_EMPTY = 'location-empty',
  LOCK = 'lock',
  MAP = 'map',
  MASTERCARD = 'mastercard',
  MASTERCARD_SQUARE = 'mastercard-square',
  MESSAGE = 'message',
  MESSAGE_EMPTY = 'message-empty',
  MINUS = 'minus',
  MUTUAL = 'mutual',
  PROFILE = 'my-profile',
  PROFILE_EMPTY = 'my-profile-empty',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_EMPTY = 'notifications-empty',
  PAWN = 'pawn',
  PAWN_EMPTY = 'pawn-empty',
  PLUS = 'plus',
  REFRESH = 'refresh',
  RESULTS = 'results',
  RESULTS_EMPTY = 'results-empty',
  SMALL_ARROW_UP = 's-arrow-up',
  SMALL_ARROW_LEFT = 's-arrow-left',
  SMALL_ARROW_RIGHT = 's-arrow-right',
  SMALL_ARROW_DOWN = 's-arrow-down',
  SEARCH = 'search',
  SHARE = 'share',
  STAR = 'star',
  STAR_EMPTY = 'star-empty',
  STREET_LOCATION = 'street-location',
  TARGET = 'target',
  THUMBS = 'thumbs',
  TIME = 'time',
  TOOLTIP = 'tooltip',
  TRASH = 'trash',
  WARNING = 'warning',
  X_CLOSE = 'x-close',
  XS_ARROW_DOWN = 'xs-arrow-down',
  XS_ARROW_LEFT = 'xs-arrow-left',
  XS_ARROW_RIGHT = 'xs-arrow-right',
  XS_ARROW_UP = 'xs-arrow-up',
}

export enum GamIconSpecialName {
  EVENT = 'pin-event',
  TOURNAMENT = 'pin-tournament',
  MY_LOCATION = 'pin-my-location',
  BADGE_MATCH = 'badge-match',
  KEY_UP = 'keyboard-up',
  KEY_DOWN = 'keyboard-down',
  SELECTED_PIN = 'selected-pin',
}
