import { createLogger } from '@/core/helpers/logger.helper';
import type { ImageApi } from '@/core/network/api/image.api';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import { HandleApiError } from '@/core/network/http/httpError';

const logger = createLogger('ImageRepository');

export class ImageRepository {
  private readonly httpAPI: ImageApi;

  constructor(httpAPI: ImageApi) {
    this.httpAPI = httpAPI;
  }

  async getImage(blobHash: string): Promise<GamResponse<Blob> | null> {
    try {
      const response = await this.httpAPI.getImage(blobHash);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }
}
