import { LoginMethodType } from '@/core/data/auth/auth.type';
import type { AuthScreenMethodGroup } from '@/views/composables/auth/useAuth.interface';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';

export const signInMethodGroups: AuthScreenMethodGroup[] = [
  {
    position: 1,
    items: [
      {
        type: LoginMethodType.Lichess,
        label: 'auth.main.lichess.button.label',
        active: true,
        position: 1,
        isPrimary: true,
      },
    ],
  },
  {
    position: 2,
    items: [
      {
        type: LoginMethodType.Email,
        label: 'auth.main.email.button.label',
        active: true,
        position: 1,
      },
    ],
  },
  {
    position: 3,
    items: [
      {
        type: LoginMethodType.Google,
        active: false,
        icon: GamIconName.GOOGLE,
        position: 2,
      },
      {
        type: LoginMethodType.Apple,
        active: false,
        icon: GamIconName.APPLE,
        position: 1,
      },
    ],
  },
];
