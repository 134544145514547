import type { UIMainMenuItem } from '@/core/data/config/uiConfig.interface';
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import NavigationHelper from '@/core/helpers/navigation.helper';
import { useAppStore } from '@/stores/app.store';
import { useAuthStore } from '@/stores/auth.store';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import type { GamNavItemType } from '@/views/composables/models/components/GamNav';
import { whenever } from '@vueuse/core';
import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

export const useNavigationStore = defineStore(GamStoreId.NAVIGATION, () => {
  const isNavVisible = ref<boolean>(false);
  const maxNavItems = ref<number>(5);
  const appStore = useAppStore();
  const authStore = useAuthStore();
  const { activeBreakpoint } = storeToRefs(appStore);
  const isMobile = ref<boolean>(false);
  const isCollapsed = ref<boolean>(true);
  const isLoading = ref<boolean>(false);
  const isMoreOpened = ref<boolean>(false);
  const navItems = ref<GamNavItemType[] | null>(null);

  const setNavVisible = (value: boolean): void => {
    isNavVisible.value = value;
  };

  const setMoreOpened = (value: boolean): void => {
    isMoreOpened.value = value;
  };

  const setNavItems = (mainMenuItems: UIMainMenuItem[] | null) => {
    setMobile(appStore.isMobile());
    navItems.value = mainMenuItems?.filter((item) => item.isActive) || [];
  };

  const isShowMoreBubble = (): boolean => {
    return !!getNavItemsMore()?.some((item) => item.messages?.count);
  };

  const setMenuMessageCount = (name: RouterNameType, count: number): void => {
    const navItem = navItems.value?.find((item) => item.name === name);
    if (navItem) navItem.messages = { count };
  };

  const setCollapsed = (value: boolean) => {
    isCollapsed.value = isMobile.value ? true : value;
  };

  const setMobile = (value: boolean) => {
    isMobile.value = value;
    setCollapsed(true);
  };
  const setLoading = (value: boolean) => {
    isLoading.value = value;
  };

  const getNavItems = (): GamNavItemType[] => {
    if (!navItems.value?.length) {
      return [];
    }

    let filteredNavItems = navItems.value;
    if (isMobile.value) {
      if (navItems.value.length > maxNavItems.value) {
        const items: GamNavItemType[] = navItems.value.slice(0, maxNavItems.value - 1);
        const moreItem = NavigationHelper.getMoreNavItem(maxNavItems.value, isShowMoreBubble());
        items.splice(maxNavItems.value - 1, 0, moreItem);
        filteredNavItems = items;
      } else {
        filteredNavItems = navItems.value;
      }
    } else {
      filteredNavItems = navItems.value.filter((item) => !item.showBottom);
    }

    return filteredNavItems.filter((item) => authStore.isAdmin() || !item.isRestrictedToAdmins);
  };
  const getNavItemsBottom = (): GamNavItemType[] => {
    if (navItems.value?.length && !isMobile.value) {
      return navItems.value.filter((item) => item.showBottom && (authStore.isAdmin() || !item.isRestrictedToAdmins));
    }
    return [];
  };
  const getNavItemsMore = (): GamNavItemType[] => {
    if (navItems.value && navItems.value.length > maxNavItems.value && isMobile.value) {
      return navItems.value.slice(maxNavItems.value - 1);
    }
    return [];
  };

  whenever(activeBreakpoint, () => {
    setMobile(appStore.isMobile());
  });

  return {
    navItems,
    isMobile,
    isCollapsed,
    isLoading,
    isNavVisible,
    isMoreOpened,
    setNavVisible,
    setCollapsed,
    setMoreOpened,
    setMobile,
    setLoading,
    setNavItems,
    setMenuMessageCount,
    getNavItems,
    getNavItemsBottom,
    getNavItemsMore,
  };
});
