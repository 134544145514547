import pino from 'pino';

export const LoggingLevels = {
  error: 5,
  warn: 4,
  info: 3,
  debug: 2,
  trace: 1,
};

const defaultLevel = 'info';
const classLoggers: pino.Logger[] = [];

const loggerOptions: pino.LoggerOptions = {
  name: 'Gambits Logger',
  customLevels: { ...LoggingLevels },
  useOnlyCustomLevels: true,
  level: defaultLevel,
  formatters: {
    bindings: (bindings: pino.Bindings) => {
      return { name: bindings.name };
    },
    level: (level: string) => {
      return { level };
    },
  },
};

export const createLogger = (name: string): pino.Logger => {
  const logger = pino({
    ...loggerOptions,
    name,
    browser: {
      asObject: true,
      transmit: {
        send: (level: string, event: any) => {
          const logLine = `${event.ts} ${level}: [${name}] => ${event.messages[0]}`;
          switch (level) {
            case 'debug':
              // eslint-disable-next-line no-console
              console.debug(logLine, event.messages[1] || '');
              break;
            case 'error':
              // eslint-disable-next-line no-console
              console.error(logLine, event.messages[1] || '');
              break;
            case 'warn':
              // eslint-disable-next-line no-console
              console.warn(logLine, event.messages[1] || '');
              break;
            case 'trace':
              // eslint-disable-next-line no-console
              console.trace(logLine, event.messages[1] || '');
              break;
            default:
              // eslint-disable-next-line no-console
              console.log(logLine, event.messages[1] || '');
              break;
          }
        },
      },
      write: () => {},
    },
  });

  classLoggers.push(logger);
  return logger;
};

export const logger = createLogger('Gambits Logger');

/**
 * Tries to detect logging level using VITE_LOG_LEVEL environment variable.
 */
export const detectLoggingLevel = (): void => {
  const logLevel = import.meta.env.VITE_DEFAULT_CLIENT_LOG_LEVEL;
  if (!logLevel) return;

  const possibleLevels = Object.keys(LoggingLevels);
  if (!possibleLevels.includes(logLevel)) {
    logger.warn(`VITE_LOG_LEVEL="${logLevel}" is not valid value. Valid values: [${possibleLevels}]`);
  } else {
    changeLogLevel(logLevel as keyof typeof LoggingLevels);
  }
};

/**
 * Changes log level to the specified level.
 */
export const changeLogLevel = (level: keyof typeof LoggingLevels): void => {
  const logLevels = Object.keys(LoggingLevels);
  if (!logLevels.includes(level)) {
    throw new TypeError(`"${level}" is not valid log level. Expected one of [${logLevels}].`);
  }
  logger.info(`Log level changed to "${level}"`);
  loggerOptions.level = level;
  logger.level = level; // changing framework logger log level

  // changing class loggers log level
  classLoggers.forEach((l) => (l.level = level));
};
