import type { ImageRepository } from '@/core/data/image/image.repository';
import type { ConfigService } from '@/core/domain/config.service';
import { createLogger } from '@/core/helpers/logger.helper';
import { AppEndpoint } from '@/core/network/api/constants/api.url.constant';

const logger = createLogger('ImageService');

export class ImageService {
  private readonly repository: ImageRepository;
  private readonly configService: ConfigService;
  private fileReader: FileReader;
  constructor(repository: ImageRepository, configService: ConfigService) {
    this.repository = repository;
    this.configService = configService;
    this.fileReader = new FileReader();
  }

  private readBlobImage(image: Blob, callback?: (image: string | null) => void) {
    this.fileReader.readAsDataURL(image);
    this.fileReader.onload = () => {
      const readImage = this.fileReader.result as string;
      callback?.(readImage);
    };
  }

  async getImage(blobHash: string, callback?: (image: string | null) => void): Promise<void> {
    try {
      const response = await this.repository.getImage(blobHash);
      if (response?.data) {
        this.readBlobImage(response.data, callback);
      }
    } catch (error) {
      logger.error(error);
      callback?.(null);
    }
  }

  getImageSrc = (image?: string): string => {
    if (!image) return '';
    if (image?.includes('base64')) {
      return image;
    } else {
      const url = `${this.configService.getServiceRoutingHost()}${AppEndpoint.BLOB}`;
      return url.replace(new RegExp('{blobHash}', 'g'), image.toString());
    }
  };
}
