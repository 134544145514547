/**
 * Wraps async function to make sure function is called no more than once at a time. Multiple calls
 * to this functions will return previous promise instead of making a new call to function when
 * first function has not yet completed.
 */
export const limitToOnlyOneRunningPromise = <T>(
  fn: (...args: any[]) => Promise<T>,
): ((...args: any[]) => Promise<T>) => {
  let promise: Promise<T> | null = null;
  return async (...args) => {
    if (promise === null) {
      promise = fn(...args);
      await promise.then(
        () => (promise = null),
        () => (promise = null),
      );
      return promise;
    } else {
      return promise;
    }
  };
};
