export enum GamGambitCardCategory {
  LIST = 'list',
  DETAIL = 'detail',
}

export enum GamGambitDateSize {
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum GamDateType {
  NOW = 'now',
  SOON = 'soon',
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  DAY = 'day',
}
