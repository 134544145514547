<template>
  <span
    v-touch:tap="emitIconEvent"
    :class="['svg-container', { 'dark-color': isDark, 'primary-color': isPrimary, error: isError, flag: isFlag }]"
    :style="{ fontSize: fontSizeValue, minWidth: fontSizeValue }"
    @click="emitIconEvent($event)"
  >
    <inline-svg
      v-if="svgSrc"
      :id="uuid"
      :src="`${svgSrc}`"
      :alt="`${props.name}`"
      @loaded="setIconSize($event)"
    ></inline-svg>
  </span>
</template>

<script lang="ts" setup>
import { GamIconSize, GamIconSpecialSize } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import { onMounted, onUpdated, ref, watch } from 'vue';
import InlineSvg from 'vue-inline-svg';
// @ts-ignore
import GamHelper from '@/core/helpers/app.helper';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import { select, selectAll } from 'd3';

// PROPS
const props = withDefaults(defineProps<GamIconType>(), {
  size: GamIconSize.NORMAL,
});

const emit = defineEmits([GamComponentsEmits.CLICK]);

// DATA PROPERTIES
const id = props.id || 'icon';
const uuid = ref<string>(GamHelper.createId(id));
const svgIcon = ref<SVGElement>();
const svgSrc = ref<string>('');
const fontSizeValue = ref<string>('');

// MOUNTED LIFECYCLE HOOK
onMounted(() => {
  setIconProperties();
});

onUpdated(() => {
  setIconProperties();
});

// METHODS
const setIconProperties = (): void => {
  fontSizeValue.value = setFontSize();
  if (props.name) {
    svgSrc.value = getSource();
  }
};

const setFontSize = (): GamIconSize | GamIconSpecialSize | string => {
  return props.size || GamIconSize.NORMAL;
};

const getSource = (): string => {
  if (props.isFlag) {
    return new URL(`../../ui/icons/flags/${props.name}.svg`, import.meta.url).href;
  }
  return new URL(`../../ui/icons/${props.name}.svg`, import.meta.url).href;
};

const setIconSize = (svgElement: SVGElement): void => {
  svgIcon.value = svgElement;
  const svgElementViewBox = svgIcon.value?.getAttribute('viewBox');
  if (!svgElementViewBox) {
    return;
  }
  const viewBox: number[] | null = svgElementViewBox.split(' ').map((n) => Number(n));
  if (viewBox?.length) {
    const widthToHeight = (viewBox[2] / viewBox[3]).toFixed(2);
    svgIcon.value.setAttribute('height', '1em');
    svgIcon.value.setAttribute('width', `${widthToHeight}em`);
    svgIcon.value.classList.add('svg-class');
  }
  setGradient();
};

const setGradient = (): void => {
  const gradientId: string = 'icon-primary-gradient';
  const idClass: string = `#${uuid.value}`;
  if (props.isPrimary && !props.isError) {
    if (!select(`${idClass} linearGradient`).size()) {
      const linearGradient = selectAll(idClass)
        .append('defs')
        .append('linearGradient')
        .attr('id', gradientId)
        .attr('gradientUnits', 'userSpaceOnUse');
      linearGradient.append('stop').attr('offset', '19.88%').attr('stop-color', '#FF6DA2');
      linearGradient.append('stop').attr('offset', '70.74%').attr('stop-color', '#F54CFF');
      svgIcon.value?.children[0].setAttribute('fill', `url(#${gradientId}`);
    }
  } else if (props.isDark) {
    svgIcon.value?.children[0]?.setAttribute('fill', '#181716');
  } else if (props.isError) {
    svgIcon.value?.children[0]?.setAttribute('fill', '#ff0000');
  } else {
    svgIcon.value?.children[0]?.setAttribute('fill', '#ffffff');
  }
};

const emitIconEvent = (event: Event): void => {
  emit(GamComponentsEmits.CLICK, event);
};

watch(
  () => props,
  () => {
    setFontSize();
    setGradient();
  },
  { deep: true },
);
</script>

<style lang="scss">
.svg-container {
  display: flex;

  &.flag {
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;

    //&:before {
    //  content: '';
    //  width: 100%;
    //  height: 100%;
    //  position: absolute;
    //  display: block;
    //  mix-blend-mode: overlay;
    //  box-sizing: border-box;
    //  background-image: linear-gradient(
    //    45deg,
    //    rgba(0, 0, 0, 0.2) 0,
    //    rgba(39, 39, 39, 0.22) 11%,
    //    rgba(255, 255, 255, 0.3) 27%,
    //    rgba(0, 0, 0, 0.24) 41%,
    //    rgba(0, 0, 0, 0.55) 52%,
    //    rgba(255, 255, 255, 0.26) 63%,
    //    rgba(0, 0, 0, 0.27) 74%,
    //    rgba(255, 255, 255, 0.3) 100%
    //  );
    //}
  }

  .svg-class {
    align-items: center;
    justify-content: center;
    max-height: 100%;
    width: 100%;
  }
}
</style>
