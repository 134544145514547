import type { BaseGambitDto, GambitDto } from '@/core/data/gambit/gambit.interface';
import type { GambitRepository } from '@/core/data/gambit/gambit.repository';
import type { ApiError, Result } from '@/core/network/http/httpClient.interface';
import { generalError } from '@/core/network/http/httpError';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';

export class GambitService {
  private readonly repository: GambitRepository;

  constructor(repository: GambitRepository) {
    this.repository = repository;
  }

  async getGambits(listId: GamListId): Promise<Result<BaseGambitDto[]>> {
    try {
      const response = await this.repository.getGambits(listId);
      return response?.data ? { data: response.data, cursor: response.cursor } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getGambit(id: string): Promise<Result<GambitDto>> {
    try {
      const response = await this.repository.getGambit(id);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }
}
