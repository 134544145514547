import type { GamTooltipType } from '@/views/composables/models/components/GamTooltip';
import tippy from 'tippy.js';
import type { App, DirectiveBinding } from 'vue';

export default {
  install: (vue: App) => {
    vue.directive('tooltip', {
      mounted: (el: HTMLElement, binding: DirectiveBinding<GamTooltipType>) => {
        tippy(el, binding.value);
      },
    });
  },
};
