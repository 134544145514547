export enum GamMapPinVariant {
  DEFAULT = 'default',
  HAS_GAMBIT = 'has-gambit',
  GAMBIT = 'gambit',
}

export enum GamMapPinLocation {
  MY_LOCATION = 'my-location',
  CAFE = 'cafe',
  STREET = 'street',
  GIANT = 'giant',
  CLUB = 'club',
  FAVORITE = 'favorite',
  EVENT = 'event',
  TOURNAMENT = 'tournament',
  HOME = 'home',
  OTHER = 'other',
}
