import type { FiltersDto } from '@/core/data/filter/filter.interface';
import { StorageKeyType, type StorageRepositoryInterface } from '@/core/data/storage/storage.interface';
import { createLogger } from '@/core/helpers/logger.helper';
import type { FiltersApi } from '@/core/network/api/filters.api';
import { HandleApiError } from '@/core/network/http/httpError';

const logger = createLogger('FilterRepository');

export class FilterRepository {
  private readonly httpAPI: FiltersApi;
  private readonly storage: StorageRepositoryInterface;

  constructor(httpAPI: FiltersApi, storage: StorageRepositoryInterface) {
    this.httpAPI = httpAPI;
    this.storage = storage;
  }

  async setAppFilters(): Promise<boolean> {
    try {
      const response = await this.httpAPI.getFilters();
      if (response.data?.data) {
        this.storage.set<FiltersDto>(StorageKeyType.Filters, response.data.data);
        return true;
      }
      return false;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  getAppFilters(): FiltersDto | null {
    return this.storage.get<FiltersDto>(StorageKeyType.Filters);
  }

  saveFiltersToStorage<T>(item: T, key: string): void {
    this.storage.set<T>(key, item);
  }

  removeFiltersToStorage(key: string): void {
    this.storage.remove(key);
  }

  getFiltersFromStorage<T>(key: string): T | null {
    return this.storage.get<T>(key);
  }
}
