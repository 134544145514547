import { AppEndpoint } from '@/core/network/api/constants/api.url.constant';
import type { HttpClient } from '@/core/network/http/httpClient';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import type { AxiosResponse } from 'axios';

export class ImageApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getImage(blobHash: string): Promise<AxiosResponse<GamResponse<Blob>>> {
    return await this.httpClient.get(AppEndpoint.BLOB, {
      urlParams: {
        blobHash,
      },
      config: {
        responseType: 'blob',
      },
    });
  }
}
