import type { UserGeoData } from '@/core/data/location/location.interface';
import { LocationRepository } from '@/core/data/location/location.repository';
import { createLogger } from '@/core/helpers/logger.helper';

const logger = createLogger('GeolocationService');
export class GeolocationService {
  private readonly repository: LocationRepository;
  private readonly dialogCooldown = 3600 * 2; // 3600 = 1h * 2 => 2h
  private watcherId: number | undefined;

  constructor(repository: LocationRepository) {
    this.repository = repository;
  }

  private watchGeolocation(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      this.watcherId = navigator.geolocation.watchPosition(resolve, reject);
    });
  }

  private saveGeoLocation(position: GeolocationPosition): UserGeoData {
    const geoData: UserGeoData = {
      location: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
      accuracy: position.coords.accuracy,
    };
    this.repository.setUserLocation(geoData);
    return geoData;
  }

  // Get geolocation every updateTimeout const
  public async startTracking(): Promise<UserGeoData> {
    try {
      const position: GeolocationPosition = await this.watchGeolocation();
      logger.debug('Latitude:', position.coords.latitude, 'Longitude:', position.coords.longitude);
      return this.saveGeoLocation(position);
    } catch (error) {
      logger.debug('Error getting geolocation:', error);
      return this.getUserLocation();
    }
  }

  clearPositionWatcher() {
    if (!this.watcherId) return;
    navigator.geolocation.clearWatch(this.watcherId);
  }

  getGeoData(): UserGeoData | null {
    return this.repository.getGeoData();
  }

  getUserLocation(): UserGeoData {
    return this.repository.getUserLocation();
  }

  private isCurrentGreaterThanUpdated(setDate: Date): boolean {
    const now = new Date();
    return now > setDate;
  }

  showDialog(): boolean {
    const updatedAt = this.repository.getGeoDataRefresh();

    if (updatedAt) {
      const setDate = new Date(updatedAt);
      setDate.setSeconds(setDate.getSeconds() + this.dialogCooldown);

      return this.isCurrentGreaterThanUpdated(setDate);
    }
    return true;
  }

  closeDialog(): void {
    this.repository.setGeoDataRefresh();
  }
}
