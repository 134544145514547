import type { LoginMethodType } from '@/core/data/auth/auth.type';
import type { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import {
  GamLinksType,
  GamNavMyProfileSection,
  GamNavProfileLinks,
} from '@/views/composables/constants/components/gamNav.constants';

export enum RouterNameType {
  PLAYERS = 'players',
  GAMBITS = 'gambits',
  FEED = 'feed',
  LOCATIONS = 'locations',
  MORE = 'more',
  MESSAGES = 'messages',
  NOTIFICATIONS = 'notifications',
  RESULTS = 'results',
  PROFILE = 'my-profile',
  ADMIN = 'admin',
}

export enum StaticPageName {
  TERMS = 'terms',
  PRIVACY_POLICY = 'privacy',
}

export enum RouterAuthType {
  AUTH = 'auth',
  AUTH_SELECT = 'auth-selection',
  AUTH_EMAIL = 'auth-email',
  AUTH_OTP = 'auth-otp',
  AUTH_REGISTRATION = 'auth-registration',
  AUTH_RATINGS = 'auth-ratings',
}

export enum RouterAdminType {
  USERS = 'admin-users',
  LOCATIONS = 'admin-locations',
  REPORTS = 'admin-reports',
}

export enum TabItemType {
  ALL = 'all',
  PLAYERS = 'players',
  GAMBITS = 'friends',
  FEED = 'following',
  LOCATIONS = 'followers',
  PHOTOS = 'photos',
  COMMENTS = 'comments',
  ABOUT = 'about',
}

export interface UITabItem {
  name: TabItemType;
  displayName: string;
  position: number;
  isDisabled?: boolean;
}

export interface UIConfig {
  mainMenuItems?: UIMainMenuItem[];
  authMethods?: AuthMethodItem[];
  playersListTabItems?: UITabItem[];
  gambitsListTabItems?: UITabItem[];
  locationDetailTabItem?: UITabItem[];
  myProfileSections?: UIMyProfileSectionItem[][];
  myProfileLinks?: UIMyProfileLinkItem[];
  footerItems?: UIMyProfileLinkItem[];
}

export interface GMapConfig {
  backgroundColor: string;
  disableDefaultUi: boolean;
  draggableCursor: boolean;
  draggingCursor: boolean;
  libraries: string[];
  streetViewControl: boolean;
  clickableIcons: boolean;
}

export interface UIMainMenuItem {
  name: RouterNameType;
  displayName: string;
  icon: GamIconName;
  position: number;
  isRestricted?: boolean;
  isRestrictedToAdmins?: boolean;
  showBottom?: boolean;
  isActive?: boolean;
}

export interface UIMyProfileSectionItem {
  name: GamNavMyProfileSection;
  position: number;
  hidden?: boolean;
  showArrow?: boolean;
}

export interface UIMyProfileLinkItem {
  name: GamNavProfileLinks;
  position: number;
  type: GamLinksType;
  link?: string;
}

export interface UIFooterItem extends UIMyProfileLinkItem {
  displayName?: string;
  icon?: GamIconName;
}

export interface UITabItem {
  name: TabItemType;
  displayName: string;
  position: number;
}

export interface UISettings {
  language: string;
}

export interface UILanguageItem {
  name: string;
  displayName: string;
  isActive: boolean;
  icon?: string;
}

export interface AuthMethodItem {
  type: LoginMethodType;
  position: number;
  active: boolean;
  clientId?: string;
  isPrimary?: boolean;
}

export interface CookiesConfig {
  functional: boolean;
  performance?: boolean;
}
