import type { ClusterConfig, SentryConfig } from '@/core/data/config/clientConfig.interface';
import type { MapMinMaxRadius, MapZoom, UserGeoData } from '@/core/data/location/location.interface';
import { createLogger } from '@/core/helpers/logger.helper';

const logger = createLogger('ClientConfigRepository');

export class ClientConfigRepository {
  parseServiceRoutingHost(): string {
    const routingHost = import.meta.env.VITE_SERVICE_ROUTING_HOST || '';
    logger.debug(`Service routing host: ${routingHost}`);
    return routingHost;
  }

  parseServiceSpices(): string {
    const routingHost = import.meta.env.VITE_SERVICE_SPICES || 'VITE_SERVICE_SPICES';
    logger.debug(`Service spices: ${routingHost}`);
    return routingHost;
  }

  parseClientVersion(): string {
    const clientVersion = import.meta.env.VITE_CLIENT_VERSION || '';
    logger.debug(`Client version: ${clientVersion}`);
    return clientVersion;
  }

  parseClientFullVersion(): string {
    const clientFullVersion = import.meta.env.VITE_CLIENT_FULL_VERSION || '';
    logger.debug(`Client full version: ${clientFullVersion}`);
    return clientFullVersion;
  }

  parseDefaultLanguage(): string {
    const defaultLanguage = import.meta.env.VITE_DEFAULT_CLIENT_LANGUAGE || 'en';
    logger.debug(`Client default language: ${defaultLanguage}`);
    return defaultLanguage;
  }

  parseDefaultOTPSize(): number {
    const defaultOTP = import.meta.env.VITE_DEFAULT_OTP_SIZE || '4';
    logger.debug(`Client default OTP size: ${defaultOTP}`);
    return parseInt(defaultOTP, 10);
  }

  parseDefaultOTPDelay(): number {
    const defaultOtpDelay = import.meta.env.VITE_DEFAULT_OTP_DELAY || '60';
    logger.debug(`Client default OTP delay: ${defaultOtpDelay}`);
    return parseInt(defaultOtpDelay, 10);
  }

  parseGoogleClientId(): string | undefined {
    const googleClientId = import.meta.env.VITE_AUTH_GOOGLE_CLIENT_ID || undefined;
    logger.debug(`Google client ID: ${googleClientId}`);
    return googleClientId;
  }

  parseLichessClientId(): string | undefined {
    const lichessClientId = import.meta.env.VITE_AUTH_LICHESS_CLIENT_ID || undefined;
    logger.debug(`Lichess client ID: ${lichessClientId}`);
    return lichessClientId;
  }

  parseAppleClientId(): string | undefined {
    const appleClientId = import.meta.env.VITE_AUTH_APPLE_CLIENT_ID || undefined;
    logger.debug(`Apple client ID: ${appleClientId}`);
    return appleClientId;
  }

  parseAuthMinAge(): number {
    const minAge = import.meta.env.VITE_AUTH_MIN_AGE || '14';
    logger.debug(`Auth min age: ${minAge}`);
    return parseInt(minAge, 10);
  }

  parseGMapApiKey(): string {
    const apiKey = import.meta.env.VITE_GMAP_API_KEY || '';
    logger.debug(`GMap API key: ${apiKey}`);
    return apiKey;
  }

  parseGMapMapId(): string {
    const mapId = import.meta.env.VITE_GMAP_MAP_ID || '';
    logger.debug(`GMap Map ID: ${mapId}`);
    return mapId;
  }

  parseMapMinMaxRadius(): MapMinMaxRadius {
    const mapMinRadius = import.meta.env.VITE_MAP_MIN_RADIUS || '20000';
    const mapMaxRadius = import.meta.env.VITE_MAP_MAX_RADIUS || '400000';
    logger.debug(`Map min radius: ${mapMinRadius}, max radius: ${mapMaxRadius}`);
    return {
      min: parseFloat(mapMinRadius),
      max: parseFloat(mapMaxRadius),
    };
  }

  parseMapZoom(): MapZoom {
    const mapZoom = import.meta.env.VITE_MAP_ZOOM || '15';
    const mapMinZoom = import.meta.env.VITE_MAP_MIN_ZOOM || '8';
    logger.debug(`Map default zoom : ${mapZoom}, map min zoom: ${mapMinZoom}`);
    return {
      zoom: parseInt(mapZoom, 10),
      minZoom: parseInt(mapMinZoom, 10),
    };
  }

  parseDefaultGeoData(): UserGeoData {
    const lat = import.meta.env.VITE_DEFAULT_LAT || '52.51799594941848';
    const lng = import.meta.env.VITE_DEFAULT_LNG || '13.387596436430556';

    logger.debug(`Default GeoData: ${lat.toString()}, ${lng.toString()}`);

    return {
      accuracy: 0,
      location: {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      },
    };
  }
  parseDevMode(): boolean {
    const dev = import.meta.env.DEV;
    logger.debug(`Development mode: ${dev}`);
    return dev;
  }

  parseSentryConfig(): SentryConfig {
    const env = import.meta.env;
    logger.debug(`Sentry config with dsn: ${env.VITE_SENTRY_DSN}`);

    return {
      dsn: env.VITE_SENTRY_DSN,
      tracesSampleRate: parseFloat(env.VITE_SENTRY_TRACES_RATE) || 0.1,
      tracePropagationTargets: env.VITE_SENTRY_PROPAGATION_TARGETS || ['localhost'],
      replaysSessionSampleRate: parseFloat(env.VITE_SENTRY_TRACES_RATE) || 0.05,
      replaysOnErrorSampleRate: parseFloat(env.VITE_SENTRY_TRACES_RATE) || 1.0,
    };
  }

  parseClusterConfig(): ClusterConfig {
    const env = import.meta.env;
    logger.debug('Cluster config');

    return {
      maxZoom: parseFloat(env.VITE_CLUSTER_MAX_ZOOM) || 16,
      radius: parseFloat(env.VITE_CLUSTER_RADIUS) || 40,
      minPoints: parseFloat(env.VITE_CLUSTER_MIN_POINTS) || 2,
      extent: parseFloat(env.VITE_CLUSTER_EXTENT) || 512,
      nodeSize: parseFloat(env.VITE_CLUSTER_NODE_SIZE) || 64,
    };
  }
}
