import type { ReportDto } from '@/core/data/report/report.interface';
import { ReportType } from '@/core/data/report/report.type';
import { ReportEndpoint } from '@/core/network/api/constants/api.url.constant';
import type { HttpClient } from '@/core/network/http/httpClient';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import type { AxiosResponse } from 'axios';

export class ReportApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  private getReportTypeData(type: ReportType): ReportEndpoint | string {
    switch (type) {
      case ReportType.LOCATION:
        return ReportEndpoint.LOCATION;
      case ReportType.USERS:
        return ReportEndpoint.USER;
      case ReportType.GAMBIT:
        return ReportEndpoint.GAMBIT;
      case ReportType.LOCATION_COMMENT:
        return ReportEndpoint.LOCATION_COMMENT;
      case ReportType.BLOB:
        return ReportEndpoint.BLOB;
      default:
        return '';
    }
  }

  async reportItem(id: string, type: ReportType, report: ReportDto): Promise<AxiosResponse<GamResponse<Blob>>> {
    return await this.httpClient.post(this.getReportTypeData(type), {
      data: report,
      urlParams: {
        id,
      },
    });
  }
}
