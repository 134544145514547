export enum GambitType {
  BLITZ = 'blitz',
  CLASSIC = 'classic',
  RAPID = 'rapid',
  BULLET = 'bullet',
  CASUAL = 'casual',
}

export enum GambitCategory {
  EVENT = 'event',
  LESSON = 'lesson',
  TOURNAMENT = 'tournament',
  GAMBIT = 'gambit',
}
