import type { UIFooterItem, UIMyProfileLinkItem } from '@/core/data/config/uiConfig.interface';
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { useNavigationStore } from '@/stores/navigation.store';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamLinksType } from '@/views/composables/constants/components/gamNav.constants';
import type { GamNavItemType } from '@/views/composables/models/components/GamNav';
import type { Router, RouteRecordName } from 'vue-router';

export default class NavigationHelper {
  static isMoreActive(currentRoute?: RouteRecordName | null): boolean | undefined {
    const navItems = useNavigationStore().getNavItemsMore();
    return navItems?.some((item) => item.name === currentRoute);
  }

  static getMoreNavItem(position: number, showMessages?: boolean): GamNavItemType {
    return {
      name: RouterNameType.MORE,
      showMore: true,
      displayName: 'app.nav.more',
      icon: GamIconName.DOTS_H_EMPTY,
      messages: showMessages ? { count: 1 } : undefined,
      position,
    };
  }

  static async openLink(router: Router, link: UIMyProfileLinkItem | UIFooterItem) {
    switch (link.type) {
      case GamLinksType.STATIC: {
        await router.push({ name: link.name });
        break;
      }
      case GamLinksType.EXTERNAL: {
        if (link.link) {
          const newWindow = window.open(link.link, '_blank');
          if (newWindow) {
            newWindow.focus();
          }
        }
        break;
      }
      case GamLinksType.MAIL: {
        window.location.href = 'mailto:d4d5c4@gambits.live';
        break;
      }
      default:
        break;
    }
  }
}
