import { StorageKeyType } from '@/core/data/storage/storage.interface';
import type { StorageRepository } from '@/core/data/storage/storage.repository';
import type { BaseUserInfo, ProfileRatingsUpdatePayload } from '@/core/data/user/user.interface';
import { createLogger } from '@/core/helpers/logger.helper';
import type { RatingApi } from '@/core/network/api/rating/rating.api';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import { HandleApiError } from '@/core/network/http/httpError';
import { GamRatingPlatform } from '@/views/composables/constants/main/ratings.constants';

const logger = createLogger('RatingRepository');

export class RatingRepository {
  private readonly httpAPI: RatingApi;
  private readonly session: StorageRepository;

  constructor(httpAPI: RatingApi, session: StorageRepository) {
    this.httpAPI = httpAPI;
    this.session = session;
  }

  async updateRatings(ratings: ProfileRatingsUpdatePayload): Promise<GamResponse<boolean>> {
    try {
      const response = await this.httpAPI.updateRatings(ratings);
      if (response.data?.data) {
        this.session.set<BaseUserInfo>(StorageKeyType.UserInfo, response.data.data);
        return { data: true };
      }
      return { data: false };
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  setConnect(platform: GamRatingPlatform): void {
    this.session.set<GamRatingPlatform>(StorageKeyType.Connect, platform);
  }

  getConnect(): GamRatingPlatform | null {
    return this.session.get<GamRatingPlatform>(StorageKeyType.Connect);
  }

  removeConnect(): void {
    this.session.remove(StorageKeyType.Connect);
  }
}
