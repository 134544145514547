export enum GamSwitchValueType {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  SWITCH = 'switch',
  CHECKED = 'checked',
}

export enum GamSwitchPosition {
  LEFT = 'left',
  RIGHT = 'right',
}
