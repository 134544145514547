import type { RatingRepository } from '@/core/data/rating/rating.repository';
import type { ProfileRatingsUpdatePayload } from '@/core/data/user/user.interface';
import type { ApiError, Result } from '@/core/network/http/httpClient.interface';
import { generalError } from '@/core/network/http/httpError';
import { GamRatingPlatform } from '@/views/composables/constants/main/ratings.constants';

export class RatingService {
  private readonly repository: RatingRepository;

  constructor(repository: RatingRepository) {
    this.repository = repository;
  }

  async updateRatings(ratings: ProfileRatingsUpdatePayload): Promise<Result<boolean>> {
    try {
      const response = await this.repository.updateRatings(ratings);
      return response ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  setConnect(platform: GamRatingPlatform): void {
    this.repository.setConnect(platform);
  }

  getConnect(): GamRatingPlatform | null {
    return this.repository.getConnect();
  }

  removeConnect(): void {
    this.repository.removeConnect();
  }
}
