export enum GamComponentsEmits {
  CLICK = 'click',
  ADD = 'add',
  SUCCESS = 'success',
  OPEN_MORE = 'open-more',
  UPDATE = 'update',
  UPDATE_CHECKED = 'update:checked',
  UPDATE_VALUE = 'update:modelValue',
  UPDATE_CHECKBOX = 'update:checkbox',
  INFINITE = 'infinite',
  CHANGE = 'change',
  CLEAR = 'clear',
  CLOSE = 'close',
  EXPAND = 'expand',
  DELETE = 'delete',
  REPORT = 'report',
  INPUT = 'input',
  MOVE = 'move',
}
