<template>
  <dialog ref="dialog" class="gam-dialog" @close="hideDialog">
    <transition name="fade">
      <div v-if="visible" class="gam-dialog-modal" :class="{ prevent: preventClose, mobile: isMobile, bg: background }">
        <div v-on-click-outside="closeModal" class="gam-dialog-content" :style="getBgStyle">
          <slot />
          <div v-if="!preventClose && !hideBack" class="back-button">
            <gam-button v-bind="getBackButton" />
          </div>
        </div>
      </div>
    </transition>
  </dialog>
</template>

<script setup lang="ts">
import { useAppStore } from '@/stores/app.store';
import { useNavigationStore } from '@/stores/navigation.store';
import GamButton from '@/views/components/GamButton.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { DialogType } from '@/views/composables/models/dialog.interface';
import { vOnClickOutside } from '@vueuse/components';
import { storeToRefs } from 'pinia';
import { computed, ref, type StyleValue } from 'vue';

const props = defineProps<DialogType>();
const emits = defineEmits([GamComponentsEmits.CLOSE]);
const dialog = ref<HTMLDialogElement>();
const visible = ref(false);
const appStore = useAppStore();
const navStore = useNavigationStore();
const { isMobile } = storeToRefs(navStore);

const showModal = () => {
  dialog.value?.showModal();

  if (dialog.value) {
    dialog.value?.addEventListener('cancel', (event) => {
      if (props.preventClose) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }
  appStore.setDialogOpened(true);
  visible.value = true;
};

const closeModal = (): void => {
  if (!props.preventClose) {
    dialog.value?.close();
  }
};

const hideDialog = (): void => {
  appStore.setDialogOpened(false);
  visible.value = false;
  emits(GamComponentsEmits.CLOSE);
};

const getBgStyle = computed((): StyleValue => {
  const bg = props.background ? `/images/dialogs/${props.background}` : undefined;
  return {
    background: bg ? `var(--color-dark-700) url(${bg})` : 'var(--color-dark-700)',
  };
});

const getBackButton = computed((): GamButtonType => {
  return {
    leftIcon: GamIconName.X_CLOSE,
    isIconOnly: true,
    size: GamButtonSize.ACTIONS,
    variant: GamButtonVariant.PRIMARY,
    callback: () => {
      closeModal();
    },
  };
});

defineExpose({
  show: showModal,
  close: closeModal,
  visible,
});
</script>

<style lang="scss">
@use '@/ui/css/partial';

.gam-dialog {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: none;
  border: 0;
  margin: 0 auto;
  transition: visibility var(--transition-loader);
  color: var(--color-white-100);

  &::backdrop {
    background: rgba(24, 23, 22, 0.7);
  }

  .gam-dialog-modal {
    display: flex;
    position: relative;
    width: auto;
    max-width: 500px;
    min-height: 100%;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &.mobile {
      max-width: 88vw;
    }

    &.bg {
      .gam-dialog-content {
        min-height: 600px;
      }
    }

    .gam-dialog-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: fit-content;
      pointer-events: auto;
      align-items: center;
      justify-content: flex-end;
      flex-shrink: 0;
      padding: var(--spacing-menu) var(--spacing-menu) var(--spacing-xxl) var(--spacing-menu);
      border-radius: var(--radius-large);
      background: var(--color-dark-700);
      background-clip: padding-box;
      background-size: cover !important;
      box-shadow: var(--shadow-backdrop-panel-downer) var(--shadow-pink-15);
      z-index: 100;
      @extend .gam-special-border !optional;

      &:before {
        padding: 1px 0 0;
        background: var(--color-linear-gradient-70);
      }

      .back-button {
        position: absolute;
        bottom: -16px;
      }
    }

    &.prevent {
      .gam-dialog-content {
        padding: var(--spacing-menu);
      }
    }
  }
}
</style>
