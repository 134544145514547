<template>
  <div :class="['gam-chip', getClasses]">
    <div :class="['gam-chip-container', getContainerClasses]">
      <div v-if="getLeftIcon" class="icon">
        <gam-icon v-bind="getLeftIcon" />
      </div>
      <div class="content" v-html="getLabel()" />
      <div v-if="getRightIcon" class="icon">
        <gam-icon v-bind="getRightIcon" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { localize } from '@/core/gambits';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamChipSize, GamChipVariant } from '@/views/composables/constants/components/gamChip.constants';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamChipType } from '@/views/composables/models/components/GamChip';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import { computed } from 'vue';

// PROPS
const props = withDefaults(defineProps<GamChipType>(), {
  size: GamChipSize.NORMAL,
  variant: GamChipVariant.DEFAULT,
});

const getIconValues = (name: GamIconName): GamIconType => {
  return {
    name,
    size: iconSize.value,
    isPrimary: isPrimary.value,
    isDark: isDark.value,
    isError: isError.value,
  };
};

const getLabel = (): string => {
  if (!props.label) return '1234';
  return localize(props.label);
};

const getClasses = computed(() => ({
  skeleton: !props.label,
  small: props.size === GamChipSize.SMALL,
  'x-small': props.size === GamChipSize.X_SMALL,
}));

const isDark = computed((): boolean => {
  return props.isActive && !props.isOutline && props.variant === GamChipVariant.DEFAULT;
});

const isPrimary = computed((): boolean => {
  return !(props.isActive && !props.isOutline) && props.variant === GamChipVariant.PRIMARY;
});

const isError = computed((): boolean => {
  return !(props.isActive && !props.isOutline) && props.variant === GamChipVariant.ERROR;
});

const iconSize = computed((): GamIconSize => {
  return props.size === GamChipSize.SMALL || GamChipSize.X_SMALL ? GamIconSize.SMALL : GamIconSize.NORMAL;
});

const getContainerClasses = computed(() => ({
  'is-outline': props.isOutline,
  'is-active': props.isActive,
  'is-default': props.variant === GamChipVariant.DEFAULT,
  'is-primary': props.variant === GamChipVariant.PRIMARY,
  'is-error': props.variant === GamChipVariant.ERROR,
}));

const getLeftIcon = computed((): GamIconType | undefined => {
  if (props.leftIcon) {
    return getIconValues(props.leftIcon);
  }
  return undefined;
});

const getRightIcon = computed((): GamIconType | undefined => {
  if (props.rightIcon) {
    return getIconValues(props.rightIcon);
  }
  return undefined;
});
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  pointer-events: none;

  &.small,
  &.x-small {
    .gam-chip-container {
      padding: var(--spacing-xxs) var(--spacing-md);
      min-width: 20px;
      height: 20px;

      .content {
        font-size: 12px;
      }
    }
  }

  &.x-small {
    .gam-chip-container {
      padding: var(--spacing-xs) !important;
    }
  }

  .gam-chip-container {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-normal);
    position: relative;
    padding: var(--spacing-xs) var(--spacing-md);
    height: 26px;
    gap: 4px;
    backdrop-filter: blur(15px);
    @extend .gam-special-border !optional;

    .content {
      font-size: 14px;
      font-weight: 500;
    }

    .icon {
      position: relative !important;
    }

    &.is-default {
      background: var(--color-white-10);

      .content {
        color: var(--color-white-100);
      }

      &.is-outline {
        background: none !important;

        &:before {
          background: var(--color-white-10);
        }

        .content {
          color: var(--color-white-100) !important;
        }

        &.is-active {
          &:before {
            background: var(--color-white-100);
          }
        }
      }

      &.is-active {
        background: var(--color-white-100);

        .content {
          color: var(--color-dark-700);
        }
      }
    }

    &.is-primary {
      background: var(--color-linear-gradient-30);

      .content {
        background: var(--color-linear-gradient);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.is-outline {
        background: none !important;

        &:before {
          background: var(--color-linear-gradient-30);
        }

        .content {
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &.is-active {
          &:before {
            background: var(--color-linear-gradient);
          }

          .content {
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      &.is-active {
        background: var(--color-linear-gradient);

        .content {
          color: var(--color-white-100);
          -webkit-text-fill-color: var(--color-white-100);
        }
      }
    }

    &.is-error {
      background: var(--color-red-30);

      .content {
        color: var(--color-red);
      }

      &.is-outline {
        background: none !important;

        &:before {
          background: var(--color-red-30);
        }

        .content {
          color: var(--color-red) !important;
        }

        &.is-active {
          border-color: var(--color-red);

          &:before {
            background: var(--color-red);
          }
        }
      }

      &.is-active {
        background: var(--color-red);

        .content {
          color: var(--color-white-100);
        }
      }
    }
  }
}
</style>
