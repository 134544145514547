import type { UITabItem } from '@/core/data/config/uiConfig.interface';
import { TabItemType } from '@/core/data/config/uiConfig.interface';
import { gambits } from '@/core/gambits';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import type { GamTabItemType } from '@/views/composables/models/components/GamTab';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFilterStore = <T>(listId: GamListId) =>
  defineStore(`${GamStoreId.FILTER}-${listId}`, () => {
    const listTabItems = ref<GamTabItemType[] | null>(null);
    const filters = ref<T | null>();

    const init = () => {
      filters.value = getFilters();
    };

    const setListTabItems = (tabItems: UITabItem[] | null) => {
      listTabItems.value = tabItems;
      setActiveTab();
    };

    const setActiveTab = (name?: TabItemType): void => {
      if (name) {
        listTabItems.value?.map((item) => {
          item.isActive = item.name === name;
        });
      } else if (listTabItems.value?.length) {
        listTabItems.value[0].isActive = true;
      }
    };

    const getActiveTab = (): TabItemType | undefined => {
      const activeTabItem: GamTabItemType | undefined = listTabItems.value?.find(
        (item) => item.isActive && item.name !== TabItemType.ALL,
      );

      return activeTabItem?.name;
    };

    const getFilters = (): T | null | undefined => {
      const storageFilters = gambits.filterService.getSavedFilters<T>(listId);
      return filters.value || storageFilters;
    };

    const isFiltersApplied = (): boolean => {
      const filters = getFilters();
      if (!filters) return false;

      for (const key in filters) {
        if (filters[key] !== undefined) {
          return true;
        }
      }
      return false;
    };

    const setFilters = (newFilters?: T | null): void => {
      filters.value = newFilters;
      gambits.filterService.saveFiltersToStorage(listId, filters.value);
    };

    return {
      listTabItems,
      filters,
      init,
      setListTabItems,
      setActiveTab,
      getActiveTab,
      getFilters,
      setFilters,
      isFiltersApplied,
    };
  });
