import type { BaseGambitDto } from '@/core/data/gambit/gambit.interface';
import type { GambitType } from '@/core/data/gambit/gambit.type';
import { GambitCategory } from '@/core/data/gambit/gambit.type';
import { ObjectType } from '@/core/data/localization/localization.type';
import { localize, localizeType, localizeWithValues } from '@/core/gambits';
import { GamChipSize, GamChipVariant } from '@/views/composables/constants/components/gamChip.constants';
import { GamDateType } from '@/views/composables/constants/components/gamGambit.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamChipType } from '@/views/composables/models/components/GamChip';

export default class GambitHelper {
  private static isTomorrow(date: Date): boolean {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate.toDateString() === date.toDateString();
  }

  private static isWithin30Minutes(date: Date) {
    const now = new Date();
    const diffInMinutes = (now.getTime() - date.getTime()) / 1000 / 60; // Convert milliseconds to minutes
    return Math.abs(diffInMinutes) <= 30;
  }

  private static isWithinRange(date1: Date, date2?: Date | null): boolean {
    const now = new Date();
    if (!date2) {
      const ninetyMinutesLater = new Date(date1.getTime() + 90 * 60 * 1000); // 90 minutes in milliseconds
      return now >= date1 && now <= ninetyMinutesLater;
    } else {
      return now >= date1 && now <= date2;
    }
  }

  static getGambitsDateType(startDate: string, isEnd: boolean, endDate?: string | null): GamDateType {
    const todayDate = new Date();
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : null;

    if (this.isWithinRange(start, end) && !isEnd) {
      return GamDateType.NOW;
    } else if (this.isWithin30Minutes(start)) {
      return GamDateType.SOON;
    } else if (this.isTomorrow(start)) {
      return GamDateType.TOMORROW;
    } else if (todayDate.toDateString() === start.toDateString()) {
      return GamDateType.TODAY;
    }

    return GamDateType.DAY;
  }

  static getBaseGambitTag(label: string, isSmall: boolean, icon?: GamIconName): GamChipType {
    return {
      label: label.toUpperCase(),
      size: isSmall ? GamChipSize.SMALL : GamChipSize.NORMAL,
      variant: GamChipVariant.DEFAULT,
      isOutline: false,
      leftIcon: icon,
    };
  }

  private static getGambitTagPlayer(playerCount: number, isSmall: boolean): GamChipType[] {
    const icon = GamIconName.PAWN;
    if (playerCount) {
      return [
        this.getBaseGambitTag(
          localizeWithValues('gambit.detail.tag.player.present', [playerCount.toString()]),
          isSmall,
          icon,
        ),
      ];
    } else {
      return [this.getBaseGambitTag(localize('gambit.detail.tag.player.needed'), isSmall, icon)];
    }
  }

  private static getGambitTagTypes(types: GambitType[], isSmall: boolean): GamChipType[] {
    return types.map((type) => {
      const typeLocalized = localizeType(ObjectType.GAMBIT, type);
      return this.getBaseGambitTag(typeLocalized, isSmall);
    });
  }

  private static getGambitTagCategory(category: GambitCategory, isSmall: boolean): GamChipType[] {
    if (category === GambitCategory.GAMBIT) return [];
    return [this.getBaseGambitTag(localize(`gambit.detail.tag.category.${category.toLowerCase()}`), isSmall)];
  }

  private static getGambitTagTimed(isSmall: boolean, timed?: boolean): GamChipType[] {
    if (!timed) return [];
    return [this.getBaseGambitTag(localize('gambit.detail.tag.timed'), isSmall)];
  }

  private static getGambitTagStream(isSmall: boolean, stream?: boolean): GamChipType[] {
    if (!stream) return [];
    return [this.getBaseGambitTag(localize('gambit.detail.tag.streamed'), isSmall)];
  }

  static getGambitsTags(gambit: BaseGambitDto, isSmall: boolean): GamChipType[] {
    return [
      ...this.getGambitTagPlayer(gambit.playersCount, isSmall),
      ...this.getGambitTagTypes(gambit.gambitType, isSmall),
      ...this.getGambitTagCategory(gambit.gambitCategory, isSmall),
      ...this.getGambitTagTimed(isSmall, gambit.timed),
      ...this.getGambitTagStream(isSmall, gambit.stream),
    ];
  }
}
