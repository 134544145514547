import type { ReportRepository } from '@/core/data/report/report.repository';
import type { AllReportReasons, ReportType } from '@/core/data/report/report.type';
import { BlobSource } from '@/core/data/report/report.type';
import type { ApiError, Result } from '@/core/network/http/httpClient.interface';
import { generalError } from '@/core/network/http/httpError';
import type { AddReportForm } from '@/views/composables/models/form.interface';

export class ReportService {
  private readonly repository: ReportRepository;
  constructor(repository: ReportRepository) {
    this.repository = repository;
  }

  async addReport(
    id: string,
    type: ReportType,
    form: AddReportForm,
    blobSource?: BlobSource,
  ): Promise<Result<boolean>> {
    try {
      const response = await this.repository.addReport(id, type, {
        reason: form.reason as AllReportReasons,
        description: form.message || undefined,
        blobSource,
      });
      return response ? { data: response } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }
}
