import type { BaseGambitDto, GambitDto } from '@/core/data/gambit/gambit.interface';
import type { UserDto } from '@/core/data/user/user.interface';
import { baseGambitFields } from '@/core/network/api/constants/api.fields.constant';
import { GambitEndpoint } from '@/core/network/api/constants/api.url.constant';
import { HttpClient } from '@/core/network/http/httpClient';
import type { GamResponse, GetGambitTableFilters, GetTableParams } from '@/core/network/http/httpClient.interface';
import { useTableStore } from '@/stores/table.store';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamFilterSortGambits } from '@/views/composables/constants/main/filter.constants';
import type { AxiosResponse } from 'axios';

export class GambitApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  private getGambitsParams(id: GamListId): GetGambitTableFilters {
    const store = useTableStore<UserDto>(id)();

    return {
      ...store.tableParams,
      ...store.getLocationParam(),
      fields: baseGambitFields.join(','),
      sortBy: GamFilterSortGambits.GAMBIT_TIME,
    };
  }

  async getGambits(listId: GamListId): Promise<AxiosResponse<GamResponse<BaseGambitDto[]>>> {
    return this.httpClient.get<BaseGambitDto[], GetGambitTableFilters>(GambitEndpoint.GET_GAMBITS, {
      config: {
        params: this.getGambitsParams(listId),
      },
    });
  }

  async getGambit(id: string): Promise<AxiosResponse<GamResponse<GambitDto>>> {
    return this.httpClient.get<GambitDto, GetTableParams>(GambitEndpoint.GET_GAMBIT_DETAILS, {
      urlParams: { id },
      config: {
        params: {
          fields: baseGambitFields.join(','),
        },
      },
    });
  }
}
