export const personalUserFields = [
  'id',
  'firstName',
  'lastName',
  'username',
  'country',
  'emoji',
  'ratings',
  'playerTypes',
  'title',
  'profileImageBlobHash',
  'chessBoardImageBlobHash',
];
export const baseUserFields = [...personalUserFields, 'status', 'distance', 'featured'];

export const adminUserFields = [...personalUserFields, 'age', 'createdAt'];

export const ratingsFields = [
  'lichessComClassicalRating',
  'lichessComRapidRating',
  'lichessComBlitzRating',
  'lichessComBulletRating',
  'chessComBlitzRating',
  'chessComRapidRating',
  'chessComBulletRating',
  'chessComClassicalRating',
  'fideRating',
  'rating',
];

export const baseUserInfoFields = [
  ...personalUserFields,
  'email',
  'birthDate',
  'aboutMe',
  'provider',
  'location',
  'isPayingUser',
  'isAdmin',
  'preferredPlayingTime',
  ...ratingsFields,
];

export const detailUserFields = [
  ...baseUserInfoFields,
  'uid',
  'age',
  'mutual',
  'reliability',
  'friends',
  'mutualFriends',
  'players',
  'mutualPlayers',
  'gambitsCreated',
  'gambitsWon',
  'gambitsDraw',
  'gambitsPlayed',
  'gambitsNextMove',
  'likes',
  'upcomingGambit',
  'followers',
  'following',
];

export const baseLocationFields = [
  'id',
  'coordinates',
  'locationTypes',
  'private',
  'favorite',
  'name',
  'address',
  'rating',
  'ratingCount',
  'image',
  'gambit',
  'distance',
  'topLocation',
];

export const detailsLocationFields = [
  'id',
  'coordinates',
  'locationTypes',
  'private',
  'favorite',
  'name',
  'address',
  'rating',
  'ratingCount',
  'about',
  'user',
  'chessSet',
  'numberOfChessboards',
  'numberOfGiantChessboards',
  'canComment',
  'createdAt',
  'updatedAt',
];

export const adminLocationFields = [...detailsLocationFields, 'verified'];

export const detailsLocationImagesFields = ['id', 'imageBlobHash', 'liked', 'numberOfLikes', 'user', 'createdAt'];

export const detailsLocationCommentsFields = ['id', 'message', 'rating', 'user', 'createdAt'];

export const baseGambitFields = [
  'id',
  'start',
  'end',
  'gambitCategory',
  'gambitType',
  'timed',
  'stream',
  'playersCount',
  'distance',
  'address',
  'coordinates',
  'isMyGambit',
];
